import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { TableHeader } from '../../components/Table/TableHeader';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import SuccessButton from '../../components/infrastructure/Buttons/SuccessButton';

const EditTakaModal = ({
    editTakaDetailsModalOpen,
    setEditTakaDetailsModalOpen,
    selectedBatchForEdit,
    setSelectedBatchForEdit,
    formik,
}) => {
    return (
        <ModalBasic
            setModalOpen={setEditTakaDetailsModalOpen}
            ignoreClick={true}
            title={'Edit Taka Details'}
            modalOpen={editTakaDetailsModalOpen}
        >
            <div className="p-4">
                {selectedBatchForEdit?.takaDetails?.map((ele, i) => (
                    <div className="flex">
                        <table className="table-auto w-full">
                            <TableHeader
                                headers={[
                                    {
                                        name: 'Sr No.',
                                        style: {
                                            minWidth: '20px',
                                        },
                                    },
                                    {
                                        name: 'Taka No',
                                        style: {
                                            minWidth: '80px',
                                        },
                                    },
                                    {
                                        name: 'Stock Quantity',
                                        style: {
                                            minWidth: '80px',
                                        },
                                    },
                                    {
                                        name: 'Send Quantity',
                                        style: {
                                            minWidth: '20px',
                                        },
                                    },
                                    {
                                        name: 'Remaining',
                                        style: {
                                            minWidth: '80px',
                                        },
                                    },
                                    {
                                        name: 'Action',
                                        style: {
                                            minWidth: '80px',
                                        },
                                    },
                                ]}
                            />
                            <tbody className="text-sm text-center">
                                <td>{i + 1}</td>
                                <td>{ele.takaNo}</td>
                                <td>
                                    <FormikInputGroup
                                        name={`batches.${selectedBatchForEdit.index}.takaDetails.${i}.stockQuantity`}
                                        readOnly
                                        formik={formik}
                                    />
                                </td>
                                <td>
                                    <FormikInputGroup
                                        name={`batches.${selectedBatchForEdit.index}.takaDetails.${i}.sendQuantity`}
                                        formik={formik}
                                        readOnly={
                                            formik.values.batches[
                                                selectedBatchForEdit.index
                                            ].takaDetails[i]?.status ===
                                            'inactive'
                                        }
                                    />
                                </td>
                                <td>
                                    <FormikInputGroup
                                        name={`batches.${selectedBatchForEdit.index}.takaDetails.${i}.remaining`}
                                        value={
                                            formik.values.batches[
                                                selectedBatchForEdit.index
                                            ].takaDetails[i].stockQuantity -
                                            formik.values.batches[
                                                selectedBatchForEdit.index
                                            ].takaDetails[i].sendQuantity
                                        }
                                        readOnly
                                        formik={formik}
                                    />
                                </td>
                                <td>
                                    {formik.values.batches[
                                        selectedBatchForEdit.index
                                    ].takaDetails[i]?.status === 'active' ? (
                                        <DangerButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                formik.setFieldValue(
                                                    `batches.${selectedBatchForEdit.index}.takaDetails.${i}.sendQuantity`,
                                                    0
                                                );
                                                formik.setFieldValue(
                                                    `batches.${selectedBatchForEdit.index}.takaDetails.${i}.status`,
                                                    'inactive'
                                                );
                                            }}
                                        >
                                            Remove
                                        </DangerButton>
                                    ) : (
                                        <SuccessButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                formik.setFieldValue(
                                                    `batches.${selectedBatchForEdit.index}.takaDetails.${i}.sendQuantity`,
                                                    formik.values.batches[
                                                        selectedBatchForEdit
                                                            .index
                                                    ].takaDetails[i]
                                                        ?.stockQuantity
                                                );
                                                formik.setFieldValue(
                                                    `batches.${selectedBatchForEdit.index}.takaDetails.${i}.status`,
                                                    'active'
                                                );
                                            }}
                                        >
                                            Add
                                        </SuccessButton>
                                    )}
                                </td>
                            </tbody>
                        </table>
                    </div>
                ))}
                <div>
                    <PrimaryButton
                        type="button"
                        onClick={() => {
                            setEditTakaDetailsModalOpen(false);
                        }}
                    >
                        Submit
                    </PrimaryButton>
                </div>
            </div>
        </ModalBasic>
    );
};

export default EditTakaModal;
