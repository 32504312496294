import React, { useState } from 'react';

import { useFormik } from 'formik';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import { fetchInvoices } from '../../app/reducers/Invoice/invoice.slice';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { saveAs } from 'file-saver';
import { ClipLoader } from 'react-spinners';
import { parseToFloat } from '../../utils/Utils';

const ViewInterestCalculation = () => {
    const dispatch = useDispatch();
    const [invoiceData, setInvoiceData] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [average, setAverage] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            customer: '',
            from: '',
            to: '',
            days: '',
            interest: '',
        },
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let data = {
                customer_id: values.customer ? values.customer : '',
                date_start: values.from
                    ? moment(values.from).format('YYYY-MM-DD')
                    : '',
                date_end: values.to
                    ? moment(values.to).format('YYYY-MM-DD')
                    : '',
                status: 'paid',
            };
            let response = await dispatch(fetchInvoices(data));
            let docs = response?.payload?.invoices;
            let updatedDocs = docs?.map((detail) => {
                let invoiceDate = moment(detail?.date);
                let lastPaymentDate = moment(detail?.last_payment_date);
                let diffDays = lastPaymentDate.diff(invoiceDate, 'days');
                let graceDays = diffDays - values.days;

                //Calculate Inerest
                let dailyRate = values.interest / 365;
                let totalInterest = dailyRate * graceDays;
                let totalAmount = (detail.total * totalInterest) / 100;
                return {
                    ...detail,
                    graceDays: graceDays,
                    dateDiff: diffDays,
                    interestAmount: totalAmount.toFixed(2),
                };
            });
            console.log(updatedDocs, 'updatedDocs');
            setInvoiceData(updatedDocs);
            const totalAmount = Array.isArray(updatedDocs)
                ? updatedDocs
                      .reduce(
                          (acc, item) =>
                              acc + (parseToFloat(item.interestAmount) || 0),
                          0
                      )
                      .toFixed(2)
                : 0;

            const totalGraceDays = Array.isArray(updatedDocs)
                ? updatedDocs
                      .reduce(
                          (acc, item) => acc + (parseInt(item.graceDays) || 0),
                          0
                      )
                      .toFixed(2)
                : 0;
            let averageGaceDays = Math.floor(
                totalGraceDays / updatedDocs.length
            );
            setAverage(averageGaceDays);
            setTotalAmount(totalAmount);
            setLoading(false);
        },
    });

    const cols = [
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                // console.log(row?.original, "check");
                return moment(row.original.date).format('DD/MM/YYYY');
            },
        },
        {
            Header: 'Invoice No.',
            Cell: ({ row }) => {
                return row.original.invoice_number;
            },
        },
        {
            Header: 'Customer Name',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.company_name}</span>
                    </div>
                );
            },
        },
        {
            Header: 'Payment Date',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>
                            {moment(row?.original?.last_payment_date).format(
                                'DD/MM/YYYY'
                            )}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: 'No. of days',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.dateDiff}</span>
                    </div>
                );
            },
        },
        {
            Header: 'Grace Period',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.graceDays}</span>
                    </div>
                );
            },
        },
        // {
        //     Header: 'Status',
        //     Cell: ({ row }) => {
        //         const status = row?.original?.current_sub_status;

        //         if (status === 'draft') {
        //             return <div className="text text-black">{status}</div>;
        //         } else {
        //             return <div className="text text-green-500">{status}</div>;
        //         }
        //     },
        // },
        {
            Header: 'AMOUNT',
            accessor: 'total',
        },
        {
            Header: 'Interest',
            Cell: ({ data, row }) => {
                return (
                    <div className=" gap-4 flex ">
                        <span>{row?.original?.interestAmount}</span>
                    </div>
                );
            },
        },
    ];

    const downloadPdf = async () => {
        setLoadingPdf(true);
        const invoiceDetail = await authAxiosInstance.post(
            `print/print-interest`,
            { invoiceData, totalAmount, average },
            { responseType: 'blob' }
        );
        console.log(invoiceDetail.data);
        saveAs(invoiceDetail.data, 'ViewInterest.pdf');
        setLoadingPdf(false);
    };

    return (
        <div className="p-4">
            <form
                onSubmit={formik.handleSubmit}
                className="grid grid-cols-2 gap-4 w-full"
            >
                <h1 className="text-lg font-bold col-span-2">
                    Calculate Customer Interest
                </h1>
                <FormikAsyncSelect
                    name="customer"
                    label="Customer"
                    formik={formik}
                    getOptions={async (value) => {
                        const action = await dispatch(
                            fetchCustomers({
                                search: value,
                                contact_type: 'customer',
                            })
                        );
                        const serverResp = action?.payload?.contacts.map(
                            (ele) => ({
                                label: ele.contact_name,
                                value: ele.contact_id,
                            })
                        );
                        return serverResp;
                    }}
                    onChange={(option) => {
                        formik.setFieldValue('customer', option.value);
                    }}
                />
                <FormikInputDateGroup
                    name="from"
                    label="From"
                    formik={formik}
                    required
                />

                <FormikInputDateGroup
                    name="to"
                    label="To"
                    formik={formik}
                    required
                />
                <FormikInputGroup
                    name="days"
                    fullWidth={true}
                    type="number"
                    label="Number of days"
                    formik={formik}
                    required
                />
                <FormikInputGroup
                    name="interest"
                    fullWidth={true}
                    type="number"
                    label="Inerest Rate(%)"
                    formik={formik}
                    required
                />
                <div></div>
                <div className="flex gap-3">
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                    <PrimaryButton
                        type="button"
                        onClick={() => {
                            setInvoiceData([]);
                            setTotalAmount(null);
                            formik.resetForm();
                        }}
                    >
                        Clear
                    </PrimaryButton>
                    {invoiceData?.length > 0 && (
                        <PrimaryButton
                            type="button"
                            onClick={() => downloadPdf()}
                            disabled={loadingPdf}
                        >
                            {loadingPdf ? <ClipLoader /> : 'Download Pdf'}
                        </PrimaryButton>
                    )}
                </div>
            </form>
            <div className="mt-2">
                <TableWithHeadingAndGlobalSearch
                    placeholder={`Search for invoice`}
                    data={invoiceData}
                    loading={loading}
                    columns={cols}
                    searchFunction={(search) => {
                        // debouncedSearch(search);
                    }}
                    heading={
                        <div className="flex gap-1">
                            <span className="text-xl">View Invoices</span>
                            <span className="text-xl">
                                {invoiceData?.length > 0 && (
                                    <div className="font-bold">
                                        ( {totalAmount} )
                                    </div>
                                )}
                            </span>
                            <span className="text-xl">
                                {invoiceData?.length > 0 && (
                                    <div className="font-bold">
                                        ( {average} )
                                    </div>
                                )}
                            </span>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default ViewInterestCalculation;
