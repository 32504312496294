import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import DisplayData from './DisplayData';
import { parseToFloat } from '../../utils/Utils';
const TableHeader = ({ headers }) => {
    return (
        <div className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200 grid grid-cols-4 gap-4">
            {headers?.map((header) => (
                <div className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                    <div className="font-semibold">{header}</div>
                </div>
            ))}
        </div>
    );
};
const DiagnosisDisplay = (props) => {
    const resultData = sessionStorage.getItem('resultData');
    const parseData = JSON.parse(resultData);

    //Po Orders Data
    let poAmountData = parseData?.poOrders?.reduce((acc, ele) => {
        if (acc.hasOwnProperty(`${ele.company_name}`)) {
            acc[`${ele.company_name}`].zohoTotal =
                acc[`${ele.company_name}`].zohoTotal + parseToFloat(ele.total);
            acc[`${ele.company_name}`].zohoEntries = [
                ...acc[`${ele.company_name}`].zohoEntries,
                ele,
            ];
        } else {
            acc[`${ele.company_name}`] = {
                zohoTotal: parseToFloat(ele.total),
                zohoEntries: [ele],
            };
        }
        return acc;
    }, {});

    //Reports Data
    let reportData = parseData.reports?.reduce((acc, ele) => {
        if (acc.hasOwnProperty(`${ele.group}`)) {
            acc[`${ele.group}`].systemTotal =
                acc[`${ele.group}`].systemTotal + parseToFloat(ele.brockrage);
            acc[`${ele.group}`].systemEntries = [
                ...acc[`${ele.group}`].systemEntries,
                ele,
            ];
        } else {
            acc[`${ele.group}`] = {
                systemTotal: parseToFloat(ele.brockrage),
                systemEntries: [ele],
            };
        }
        return acc;
    }, {});
    console.log(poAmountData, 'poAmountData');
    //Create Common Object
    let uniqueKeysObject = {};
    for (let key in poAmountData) {
        if (reportData.hasOwnProperty(key)) {
            uniqueKeysObject[key] = {
                ...poAmountData[key],
                ...reportData[key],
            };
        } else {
            uniqueKeysObject[key] = poAmountData[key];
        }
    }
    for (let key in reportData) {
        if (!poAmountData.hasOwnProperty(key)) {
            uniqueKeysObject[key] = reportData[key];
        }
    }

    let systemTotal = Object.keys(uniqueKeysObject).reduce(
        (acc, ele) =>
            (acc =
                acc +
                (uniqueKeysObject[ele]['systemTotal']
                    ? uniqueKeysObject[ele]['systemTotal']
                    : 0)),
        0
    );
    let zohoTotal = Object.keys(uniqueKeysObject).reduce(
        (acc, ele) =>
            (acc =
                acc +
                (uniqueKeysObject[ele]['zohoTotal']
                    ? uniqueKeysObject[ele]['zohoTotal']
                    : 0)),
        0
    );

    let checkDifference = Object.keys(uniqueKeysObject).reduce((acc, ele) => {
        let systemTotal = uniqueKeysObject[ele]['systemTotal']
            ? uniqueKeysObject[ele]['systemTotal']
            : 0;
        let zohoTotal = uniqueKeysObject[ele]['zohoTotal']
            ? uniqueKeysObject[ele]['zohoTotal']
            : 0;
        let diff = zohoTotal - systemTotal;
        console.log(diff, Math.sign(diff), 'diff');
        if (Math.sign(diff) != -1) {
            acc = acc + parseToFloat(diff);
        }
        return acc;
    }, 0);

    return (
        <PageWithCard heading="Result">
            <div className="flex gap-20">
                <div>
                    <div className="font-semibold text-lg mb-2">
                        {' '}
                        System Total :-{' '}
                        <span className="font-bold">
                            {systemTotal.toFixed(2)}
                        </span>
                    </div>
                    <div className="font-semibold text-lg mb-2">
                        {' '}
                        Zoho Total :-{' '}
                        <span className="font-bold">
                            {zohoTotal.toFixed(2)}
                        </span>
                    </div>
                    <div className="font-semibold text-lg mb-2">
                        {' '}
                        Difference :-{' '}
                        <span className="font-bold">
                            {(zohoTotal - systemTotal).toFixed(2)}
                        </span>
                    </div>
                    <div className="font-semibold text-lg mb-2">
                        {' '}
                        Customer Total :-{' '}
                        <span className="font-bold">
                            {parseData.customerTotal}
                        </span>
                    </div>
                </div>

                <div>
                    <div className="font-semibold text-2xl border border-solid border-black p-3 mb-2">
                        {' '}
                        Junk Total :-{' '}
                        <span className="font-bold">{parseData.junkTotal}</span>
                    </div>
                </div>
            </div>

            <div className="font-semibold text-lg mb-2">
                {' '}
                Diff Total :-{' '}
                <span className="font-bold">{checkDifference.toFixed(2)}</span>
            </div>
            <div className="font-semibold text-lg mb-2">
                {' '}
                Final Total :-{' '}
                <span className="font-bold">
                    {(parseData.junkTotal - checkDifference).toFixed(2)}
                </span>
            </div>
            <div className="font-semibold text-lg mb-2">
                {' '}
                Project Name :-{' '}
                <Link
                    className="text-indigo-600 underline font-bold"
                    to={`/ultraDiagnosis/${parseData.projectName}`}
                >
                    {parseData.projectName}
                </Link>
            </div>
            <TableHeader
                headers={[
                    'Company Name',
                    'Zoho Total',
                    'System Total',
                    'Difference',
                ]}
            />
            {Object.keys(uniqueKeysObject).map((key, i) => (
                <DisplayData key={i} data={uniqueKeysObject[key]} item={key} />
            ))}
        </PageWithCard>
    );
};

export default DiagnosisDisplay;
