import QueryString from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import PageWithCard from '../../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../../components/Table/TableWithHeadingAndSearch';

const VendorWiseMillInventory = () => {
    const { millVendorString } = useParams();
    const { vendor, location } = JSON.parse(atob(millVendorString));
    const [loading, setLoading] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);

    useEffect(() => {
        const fetchInventory = async () => {
            try {
                setLoading(true);
                const string = QueryString.stringify({
                    vendor,
                    location,
                });

                const resp = await authAxiosInstance.get(
                    `/inventory/mill/byVendor?${string}`
                );
                setInventoryData(resp.data.data.docs);
            } catch (error) {
                console.log(error);
                toast.error('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };
        fetchInventory();
    }, [millVendorString]);

    const cols = useMemo(() => {
        return [
            {
                Header: 'Bill#',
                accessor: '_id.billNo',
                Cell: ({ row }) => {
                    const string = btoa(
                        JSON.stringify({
                            location,
                            billNo: row.original._id?.billNo,
                            vendor,
                        })
                    );
                    // console.log({
                    //     location,
                    //     billNo: row.original._id?.billNo,
                    //     quality,
                    // });
                    return (
                        <Link
                            to={`/dashboard/mill/batch-inventory/${string}`}
                            className="text-indigo-600 underline"
                        >
                            {row.original._id.billNo}
                        </Link>
                    );
                },
            },
            {
                Header: 'Quality',
                accessor: 'quality.name',
            },
            {
                Header: 'location',
                accessor: 'location.name',
            },
            {
                Header: 'Mill Pending',
                Cell: ({ row }) => {
                    return (
                        <div>{row.original['stagesMap']['millPending']}</div>
                    );
                },
            },
            {
                Header: 'Mill Received',
                Cell: ({ row }) => {
                    return (
                        <div>{row.original['stagesMap']['millReceived']}</div>
                    );
                },
            },
        ];
    }, [millVendorString]);
    return (
        <PageWithCard
            heading={`${loading ? 'Loading...' : inventoryData?.[0]?.vendorName}`}
        >
            <TableWithHeadingAndSearch
                heading={'Inventory'}
                columns={cols}
                data={inventoryData}
            />
        </PageWithCard>
    );
};

export default VendorWiseMillInventory;
