import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/Auth/authSlice';
import mediaLibrarySlice from './reducers/MediaLibrary/mediaLibrarySlice';
import emailSlice from './reducers/Emails/emailSlice';
import usersSlice from './reducers/Users/usersSlice';
import projectSlice from './reducers/Project/projectSlice';
import submissionSlice from './reducers/Submission/submissionSlice';
import leaveSlice from './reducers/Leaves/leaveSlice';
import contactsSlice from './reducers/Contacts/contactsSlice';
import qualitySlice from './reducers/Quality/qualitySlice';
import customerSlice from './reducers/Customer/customerSlice';
import purchaseOrderSlice from './reducers/PurchaseOrder/purchaseOrderSlice';
import paymentTermSLice from './reducers/PaymentTerms/paymentTermSLice';
import notificationSlice from './reducers/Notification/notificationSlice';
import invoiceSlice from './reducers/Invoice/invoice.slice';
import organizationSlice from './reducers/Organization/organizationSlice';
import attributeSlice from './reducers/Attributes/attributeSlice';
import callingSlice from './reducers/Callling/callingSlice';
import printSlice from './reducers/Print/printSlice';
import noticeSlice from './reducers/Notice/noticeSlice';
import vendorSlice from './reducers/Vendors/vendorSlice';
import yarnGradeSlice from './reducers/YarnGrade/yarnGradeSlice';
import customerPreferenceSlice from './reducers/CustomerPreference/customerPreferenceSlice';
import pendingOrderListSlice from './reducers/PendingOrderList/pendingOrderListSlice';
import reminderSubjectSlice from './reducers/ReminderSubject/reminderSubjectSlice';
import reminderPopUpSlice from './reducers/ReminderPopUp/reminderPopUpSlice';
import featureSlice from './reducers/Features/featureSlice';
import locationSlice from './reducers/Location/locationSlice';
import itemsSlice from './reducers/Items/items.slice';
import millDispatchSlice from './reducers/MillDispatch/millDispatchSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        mediaLibrary: mediaLibrarySlice,
        emails: emailSlice,
        users: usersSlice,
        projects: projectSlice,
        submissions: submissionSlice,
        leaves: leaveSlice,
        contacts: contactsSlice,
        customer: customerSlice,
        purchaseOrder: purchaseOrderSlice,
        quality: qualitySlice,
        paymentTerms: paymentTermSLice,
        notification: notificationSlice,
        invoice: invoiceSlice,
        organization: organizationSlice,
        attributes: attributeSlice,
        calling: callingSlice,
        print: printSlice,
        notice: noticeSlice,
        vendors: vendorSlice,
        yarnGrade: yarnGradeSlice,
        customerPreference: customerPreferenceSlice,
        pendingOrderList: pendingOrderListSlice,
        reminderSubject: reminderSubjectSlice,
        reminderPopUp: reminderPopUpSlice,
        features: featureSlice,
        location: locationSlice,
        items: itemsSlice,
        millDispatch: millDispatchSlice,
    },
});
