import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../utils/axiosConfig';

export const createMillDispatch = async (data) => {
    try {
        const createResp = await authAxiosInstance.post('/millDispatch', data);
        return createResp;
    } catch (error) {
        console.log(error);
        toast.error('failed to create mill dispatch');
    }
};
