import { createSlice } from '@reduxjs/toolkit';
import { generateStateSingle } from '../../generators/generateState';
import {
    createData,
    deleteData,
    editData,
    fetchData,
    fetchEditData,
} from '../../generators/generateThunks';

const SLICE_NAME = 'millDispatch';

export const createMillDispatch = createData.generateThunk(
    SLICE_NAME,
    SLICE_NAME
);

const millDispatchReducer = createSlice({
    initialState: generateStateSingle(SLICE_NAME),
    name: SLICE_NAME,
    extraReducers: {
        ...createData.generateExtraReducers(createMillDispatch, SLICE_NAME),
    },
});

export const getMillDispatch = (state) => state.millDispatch;

export default millDispatchReducer.reducer;
