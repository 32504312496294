import QueryString from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';

const VendorWiseGreyInventory = () => {
    const { vendorId } = useParams();
    const { master_grey_vendor_id, location } = JSON.parse(atob(vendorId));
    const [loading, setLoading] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);

    useEffect(() => {
        const fetchInventory = async () => {
            try {
                setLoading(true);
                const string = QueryString.stringify({
                    master_grey_vendor_id,
                    location,
                });

                const resp = await authAxiosInstance.get(
                    `/inventory/byVendor?${string}`
                );
                setInventoryData(resp.data.data.docs);
            } catch (error) {
                console.log(error);
                toast.error('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };
        fetchInventory();
    }, [vendorId]);

    const cols = useMemo(() => {
        return [
            {
                Header: 'Bill#',
                accessor: '_id.billNo',
                Cell: ({ row }) => {
                    const string = btoa(
                        JSON.stringify({
                            location,
                            billNo: row.original._id?.billNo,
                            master_grey_vendor_id,
                            quality: row.original._id?.quality,
                        })
                    );
                    // console.log({
                    //     location,
                    //     billNo: row.original._id?.billNo,
                    //     quality,
                    // });
                    return (
                        <Link
                            to={`/dashboard/batch-inventory/${string}`}
                            className="text-indigo-600 underline"
                        >
                            {row.original._id.billNo}
                        </Link>
                    );
                },
            },
            {
                Header: 'Quality',
                accessor: 'quality.name',
            },
            {
                Header: 'location',
                accessor: 'location.name',
            },
            {
                Header: 'greyReceived',
                Cell: ({ row }) => {
                    return (
                        <div>{row.original['stagesMap']['greyReceived']}</div>
                    );
                },
            },
        ];
    }, [vendorId]);
    return (
        <PageWithCard
            heading={`${loading ? 'Loading...' : inventoryData?.[0]?.master_grey_vendor_name}`}
        >
            <TableWithHeadingAndSearch
                heading={'Inventory'}
                columns={cols}
                data={inventoryData}
            />
        </PageWithCard>
    );
};

export default VendorWiseGreyInventory;
