import React, { useState, useEffect } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import {
    generateValueFromStores,
    generateOptions,
    parseToFloat,
} from '../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { array, mixed, number, object, string } from 'yup';
import { X } from 'react-feather';
import ReactSelect from 'react-select/async';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { toast } from 'react-toastify';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import { GST_VLAUES } from '../../utils/dropdownOptions';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { customIDs } from '../../utils/customIds';
import ReportRow from './ReportRow';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { editProject } from '../../app/reducers/Project/projectSlice';

const TableHeader = ({ headers }) => {
    return (
        <div className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
            <div className="flex justify-between align-items-center">
                {headers?.map((header) => (
                    <div className="px-2 first:pl-9 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold">{header}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
const UltraDiagnosis = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [zohoLoading, setZohoLoading] = useState(false);
    const [initialData, setInitialData] = useState({});

    //Fetching Data Of Project
    const params = useParams();

    const findProjectDetail = async () => {
        let data = {
            project: params.project,
        };
        let string = QueryString.stringify(data);
        let project = await authAxiosInstance.get(`project?${string}`);
        let projectDetail = project?.data?.data?.docs[0];
        return projectDetail;
    };

    useEffect(async () => {
        setLoading(true);
        let checkProject = await findProjectDetail();

        let initialDetail = checkProject
            ? checkProject
            : {
                  project: '',
                  customer: '',
                  gst: '',
                  start: '',
                  end: '',
                  brockrage: '',
                  zohoTotal: 0,
                  customerTotal: 0,
                  junkTotal: 0,
                  reports: [''],
              };
        setInitialData(initialDetail);
        setLoading(false);
    }, [params]);

    let reportObj = {
        group: '',
        chalan: '',
        amount: '',
        finalAmount: '',
        brockrage: '',
        edit: true,
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: initialData,
        validationSchema: object({
            customer: string().required(),
            gst: string().required(),
            start: mixed().required(),
            end: mixed().required(),
            brockrage: number().required(),
            reports: array(),
            zohoTotal: number(),
            customerTotal: number(),
            junkTotal: number(),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                if (values.reports.length > 0) {
                    setLoading(true);
                    values.reports = values.reports.map((d) => ({
                        ...d,
                        edit: false,
                    }));
                    let editedData = { ...values };
                    dispatch(editProject(editedData));
                    const data = {
                        date_start: moment(values.start).format('YYYY-MM-DD'),
                        date_end: moment(values.end).format('YYYY-MM-DD'),
                        [`custom_field_${customIDs.purchase_custom_buyerName}_contains`]:
                            values.customer,
                    };
                    let string = QueryString.stringify(data);
                    let purchaseOrders = await authAxiosInstance.get(
                        `purchase-orders?${string}`
                    );
                    let resultData = {
                        poOrders: purchaseOrders.data?.data?.purchaseorders,
                        junkTotal: values.junkTotal ? values.junkTotal : 0,
                        customerTotal: values.customerTotal
                            ? values.customerTotal
                            : 0,
                        projectName: values.project,
                        reports: values.reports.map((el) => ({
                            ...el,
                            edit: false,
                        })),
                    };
                    sessionStorage.setItem(
                        'resultData',
                        JSON.stringify(resultData)
                    );
                    resetForm();
                    formik.setFieldValue('reports', ['']);
                    setLoading(false);
                    navigate('/diagnosisDisplay');
                } else {
                    toast.error('Please select atleast one report');
                }
            } catch (error) {
                console.log(error);
                toast.error('error transferring stock');
                resetForm();
            } finally {
                setLoading(false);
            }
        },
    });

    const saveDetails = (e) => {
        e.stopPropagation();
        localStorage.setItem('diagnosisData', JSON.stringify(formik.values));
        toast.success('Data Saved Succesfully');
    };

    const showTotal = async (e) => {
        e.stopPropagation();
        setZohoLoading(true);
        const data = {
            date_start: moment(formik.values.start).format('YYYY-MM-DD'),
            date_end: moment(formik.values.end).format('YYYY-MM-DD'),
            [`custom_field_${customIDs.purchase_custom_buyerName}_contains`]:
                formik.values.customer,
        };
        let string = QueryString.stringify(data);
        let purchaseOrders = await authAxiosInstance.get(
            `purchase-orders?${string}`
        );
        let zohoTotal = purchaseOrders.data?.data?.purchaseorders?.reduce(
            (acc, ele) => (acc = acc + parseToFloat(ele.total)),
            0
        );
        setZohoLoading(false);
        formik.setFieldValue('zohoTotal', zohoTotal ? zohoTotal : 0);
    };

    return (
        <PageWithCard heading="Ultra Diagnosis">
            <div>
                {loading ? (
                    <ClipLoader />
                ) : (
                    <form className="flex flex-col gap-4">
                        <FormikInputGroup
                            name="project"
                            label="Project"
                            onKeyPress={(e) => {
                                e.which === 13 && e.preventDefault();
                            }}
                            formik={formik}
                        />
                        <FormikAsyncSelect
                            name="customer"
                            label="Customer"
                            formik={formik}
                            getOptions={async (value) => {
                                const action = await dispatch(
                                    fetchCustomers({
                                        search: value,
                                        contact_type: 'customer',
                                    })
                                );
                                const serverResp = action.payload.contacts.map(
                                    (ele) => ({
                                        label: ele.contact_name,
                                        value: ele.contact_name,
                                    })
                                );
                                return serverResp;
                            }}
                        />
                        <FormikSelectGroup
                            formik={formik}
                            label="GST"
                            name={`gst`}
                            options={generateOptions({
                                array: GST_VLAUES,
                                labelField: 'label',
                                valueField: 'value',
                            })}
                            required
                        />
                        {formik.values.customer && (
                            <FormikInputGroup
                                name={`brockrage`}
                                label={'Brockrage'}
                                formik={formik}
                                type="number"
                                readOnly
                            />
                        )}
                        <FormikInputDateGroup
                            label="From"
                            name="start"
                            formik={formik}
                        />
                        <FormikInputDateGroup
                            label="To"
                            name="end"
                            formik={formik}
                        />
                        <PrimaryButton
                            className="w-40"
                            onClick={(e) => showTotal(e)}
                        >
                            {zohoLoading ? <ClipLoader /> : 'Show Zoho Total'}
                        </PrimaryButton>
                        {formik.values.zohoTotal > 0 && (
                            <>
                                <FormikInputGroup
                                    name={`zohoTotal`}
                                    label={'Zoho Total'}
                                    formik={formik}
                                    type="number"
                                    readOnly
                                />
                                <FormikInputGroup
                                    name={`customerTotal`}
                                    label={'Customer Total'}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'customerTotal',
                                            e.target.value
                                        );
                                        formik.setFieldValue(
                                            'junkTotal',
                                            formik.values.zohoTotal -
                                                e.target.value
                                        );
                                    }}
                                    formik={formik}
                                    type="number"
                                />
                                <FormikInputGroup
                                    name={`junkTotal`}
                                    label={'Junk Total'}
                                    value={(
                                        formik.values.zohoTotal -
                                        formik.values.customerTotal
                                    ).toFixed(2)}
                                    type="number"
                                    formik={formik}
                                    readOnly
                                />
                            </>
                        )}
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="reports"
                                render={(arrayHelpers) => {
                                    return (
                                        <>
                                            <label>Reports</label>
                                            <TableHeader
                                                headers={[
                                                    '',
                                                    'Group Name',
                                                    'Challan',
                                                    'Amount',
                                                    'Final Amount',
                                                    'Brockrage',
                                                    '',
                                                ]}
                                            />
                                            {formik.values.reports &&
                                                formik.values.reports.map(
                                                    (ele, i) => (
                                                        <ReportRow
                                                            key={i}
                                                            formik={formik}
                                                            index={i}
                                                            element={ele}
                                                            reportObj={
                                                                reportObj
                                                            }
                                                            arrayHelpers={
                                                                arrayHelpers
                                                            }
                                                        />
                                                    )
                                                )}
                                            <PrimaryButton
                                                style={{ width: '12%' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    arrayHelpers.push(
                                                        reportObj
                                                    );
                                                }}
                                            >
                                                Add More
                                            </PrimaryButton>
                                        </>
                                    );
                                }}
                            ></FieldArray>
                        </FormikProvider>
                        <div className="flex gap-3">
                            <PrimaryButton
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    formik.handleSubmit();
                                }}
                            >
                                Submit
                            </PrimaryButton>
                            {/* <PrimaryButton type="button" onClick={(e) => saveDetails(e)}>
                Save
              </PrimaryButton> */}
                        </div>
                    </form>
                )}
            </div>
        </PageWithCard>
    );
};

export default UltraDiagnosis;
