import React, { useMemo, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { useEffect } from 'react';
import { useState } from 'react';

const FormikAsyncSelect = ({
    formik,
    label = '',
    required = false,
    name,
    onChange,
    getOptions,
    defaultOptionsFields = { labelField: '_id', valueField: 'name' },
    resetOnSelect = true,
    ...props
}) => {
    const [options, setOptions] = useState([]);

    const computeValue = (options, val) => {
        // console.table({ options, val, name: name });
        const computedValue = options?.find((option) => option.value === val);
        return computeValue ? computedValue : null;
    };
    const computeDefaultOptions = async (val) => {
        const options = await getOptions(val);
        setOptions(options);
    };
    useEffect(() => {
        computeDefaultOptions(_.at(formik.values, name)[0]);
    }, [_.at(formik.values, name)[0]]);
    const handleChange = onChange
        ? onChange
        : (selectedOption) => {
              formik.setFieldValue(name, selectedOption.value);
          };
    const loadOptions = async (value) => {
        const options = await getOptions(value);
        setOptions(options);
        return options;
    };
    console.log(formik.errors);
    const randomKey = useMemo(
        () =>
            JSON.stringify(computeValue(options, _.at(formik.values, name)[0])),
        [_.at(formik.values, name)[0]]
    );
    // let optionss =  loadOptions()
    console.log(formik?.errors?.[name]);
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>

            <AsyncSelect
                name={name}
                key={randomKey}
                value={computeValue(options, _.at(formik.values, name)[0])}
                onBlur={formik.handleBlur}
                onChange={handleChange}
                loadOptions={loadOptions}
                {...props}
            />
            {_.at(formik.errors, name)[0] && _.at(formik.touched, name)[0] ? (
                <p className="text-xs text-red-500">
                    {_.at(formik.errors, name)[0]}
                </p>
            ) : null}
        </div>
    );
};

export default FormikAsyncSelect;
