import React, { useEffect, useMemo, useState } from 'react';
import _, { add } from 'lodash';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import {
    fetchPurchaseOrder,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { batch, useDispatch, useSelector } from 'react-redux';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import {
    fetchQualities,
    fetchQuality,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import AddQuantityTaka from './AddQuantityTaka';
import { PoDetailRow } from './PoDetailRow';
import {
    fetchLocations,
    getLocation,
} from '../../app/reducers/Location/locationSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { BOOLEAN } from '../../utils/dropdownOptions';
import moment from 'moment/moment';
import { TableHeader } from '../../components/Table/TableHeader';
import QueryString from 'qs';
// import { createMillDispatch } from '../../app/reducers/MillDispatch/millDispatchSlice';
import { parseToFloat } from '../../utils/Utils';
import { createMillDispatch } from '../../app/api/millDispatch';

const ReceiveGreyPurchaseOrder = () => {
    const { id: poId } = useParams();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [qtyTakaModel, setTakaModel] = useState(false);
    const [editData, setEditData] = useState(null);
    const [rate, setRate] = useState('');
    const { singlePurchaseOrder } = useSelector(getPurchaseOrder);
    const { quality } = useSelector(getQuality);
    const { location } = useSelector(getLocation);
    const locationInfoId = localStorage.getItem('locationInfoId');

    const computeGreySauda = (order) => {
        if (order?.line_items?.find) {
            const data = order.line_items.reduce((acc, ele, i) => {
                if (ele.name === 'GREY SAUDA') {
                    acc.push({ lineItemIndex: i, element: ele });
                }
                return acc;
            }, []);
            return data;
        }
        return [];
    };

    useEffect(() => {
        dispatch(fetchQualities());
        dispatch(fetchLocations());
    }, []);

    useEffect(() => {
        dispatch(fetchPurchaseOrder({ id: poId }));
    }, [poId]);

    const qualityData = useMemo(
        () => (quality?.docs ? quality.docs : []),
        [quality]
    );
    const locationData = useMemo(
        () => (location?.docs ? location.docs : []),
        [location]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: locationInfoId ? locationInfoId : '',
            vendorName: '',
            vendor: '',
            quality: '',
            billDate: '',
            qualityName: '',
            billNo: '',
            selectedSaudaIndex: '',
            remainingOrderQuantity: '',
            poDetails: [],
            brokerName: singlePurchaseOrder?.custom_field_hash?.cf_broker,
            date: singlePurchaseOrder?.date,
            master_grey_vendor_name:
                singlePurchaseOrder?.custom_field_hash?.cf_master_grey_vender,
            master_grey_vendor_id:
                singlePurchaseOrder?.custom_field_hash
                    ?.cf_master_grey_vender_unformatted,
            isOrderComplete: '',
            millVendor: '',
            millVendorName: '',
        },
        validationSchema: Yup.object({
            location: Yup.string().required(),
            vendor: Yup.string().required(),
            vendorName: Yup.string().required(),
            date: Yup.string().required(),
            billDate: Yup.string().required(),
            isOrderComplete: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            try {
                const millVendor = formik.values.millVendor;
                let addMillData = 'no';
                if (millVendor) {
                    const data = window.confirm(
                        'Mill Vendor is detected by system do you want to do automatic mill dispatch entry'
                    );
                    if (data) {
                        addMillData = 'yes';
                    }
                }
                let poDetails = formik.values.poDetails;
                setLoading(true);
                const data = values.selectedSaudaIndex.split('/');
                const saudaIndex = data[0];
                const itemIndex = data[1];
                if (poDetails?.length > 0) {
                    let payload = {
                        quality: values.quality.split('/')[0],
                        location: values.location,
                        qualityName: values.qualityName,
                        billNo: values.billNo,
                        poDetails: values.poDetails,
                        master_grey_vendor_name: values.master_grey_vendor_name,
                        master_grey_vendor_id: values.master_grey_vendor_id,
                        vendor: values.vendor,
                        vendorName: values.vendorName,
                        selectedSaudaIndex: saudaIndex,
                        date: values.date,
                        purchaseOrderId: poId,
                        isOrderComplete: values.isOrderComplete,
                    };
                    const resp = await authAxiosInstance.post(
                        `/greyPurchaseOrder`,
                        payload
                    );
                    if (addMillData === 'yes') {
                        const greyPo = resp.data.data;
                        const dispatchPayload = {
                            vendor: millVendor,
                            vendorName: values.millVendorName,
                            greyVendor: values.vendor,
                            greyVendorName: values.vendorName,
                            master_grey_vendor_name:
                                values.master_grey_vendor_name,
                            master_grey_vendor_id: values.master_grey_vendor_id,
                            location: values.location,
                            batches: [],
                        };
                        for (let i = 0; i < greyPo.poDetails.length; i++) {
                            const element = greyPo.poDetails[i];
                            const batchPayload = {
                                batchNo: `${greyPo.billNo}_${i + 1}`,
                                takaDetails: [],
                                qualityId: greyPo.quality,
                                totalMeter: element.meter,
                                totalTaka: element.taka,
                            };
                            for (
                                let j = 0;
                                j < element.takaDetail.length;
                                j++
                            ) {
                                const takaQuantity =
                                    element.takaDetail[j].quantity;
                                const takaId = element.takaDetail[j].takaId;
                                const takaPayload = {
                                    takaId,
                                    takaNo: `${greyPo.billNo}_${i + 1}_${j + 1}`,
                                    stockQuantity:
                                        parseToFloat(takaQuantity).toFixed(3),
                                    sendQuantity:
                                        parseToFloat(takaQuantity).toFixed(2),
                                    remaining: 0,
                                    greyPoRef: greyPo._id,
                                    status: 'active',
                                };
                                batchPayload.takaDetails.push(takaPayload);
                            }
                            dispatchPayload.batches.push(batchPayload);
                        }
                        // dispatch(createMillDispatch(dispatchPayload));
                        await createMillDispatch(dispatchPayload);
                    }
                    if (resp.data) {
                        setLoading(false);
                        formik.resetForm();
                    }
                }
            } catch (error) {
                console.log(error);
                toast.error('Failed to update data');
            }
        },
    });

    return (
        <div className="p-4">
            <AddQuantityTaka
                isViewTaka={qtyTakaModel}
                setViewTaka={setTakaModel}
                formik={formik}
                data={singlePurchaseOrder}
                editData={editData}
                rate={rate}
                setEditData={setEditData}
            />
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 w-full"
            >
                <h1 className="text-lg font-bold">
                    Receive Grey Purchase Order
                </h1>
                <div className="grid md:grid-cols-5 gap-2">
                    <FormikInputGroup
                        name="PoNumber"
                        formik={formik}
                        readOnly
                        value={singlePurchaseOrder.purchaseorder_number}
                        label="PO#"
                    />
                    <FormikAsyncSelect
                        name="vendor"
                        label="Vendor"
                        formik={formik}
                        getOptions={async (value) => {
                            const data = {
                                search: value,
                                contact_type: 'vendor',
                            };
                            const string = QueryString.stringify(data);
                            const contactsResp = await authAxiosInstance.get(
                                `purchase-orders/contacts?${string}`
                            );
                            const contacts = contactsResp.data.data.contacts;
                            console.log(contacts, 'cts');
                            const serverResp = contacts.map((ele) => ({
                                label: `${ele.contact_name}`,
                                value: `${ele.contact_id}/${ele.contact_name}`,
                            }));
                            return serverResp;
                        }}
                        onChange={(option) => {
                            const id = option.value.split('/')[0];
                            const vendor_name = option.value.split('/')[1];
                            formik.setFieldValue('vendorName', vendor_name);
                            formik.setFieldValue('vendor', id);
                        }}
                    />
                    <FormikInputGroup
                        name={'master_grey_vendor_name'}
                        label="Master Vendor"
                        readOnly
                        formik={formik}
                    />
                    <FormikInputDateGroup
                        name="date"
                        formik={formik}
                        label="Date"
                        readOnly
                    />
                    <FormikInputGroup
                        name="billNo"
                        formik={formik}
                        label="Bill No."
                    />
                </div>
                <div className="grid md:grid-cols-5 gap-2">
                    <FormikInputDateGroup
                        name="billDate"
                        formik={formik}
                        label="Bill Date"
                    />
                    <FormikInputGroup
                        name="brokerName"
                        label="Broker Name"
                        readOnly
                        formik={formik}
                    />
                    <FormikSelectGroup
                        formik={formik}
                        name={`location`}
                        label={'Select Location'}
                        options={locationData.map((ele) => ({
                            label: ele.name,
                            value: ele._id,
                        }))}
                    />
                    <FormikSelectGroup
                        formik={formik}
                        name={`selectedSaudaIndex`}
                        label={'Sauda Index'}
                        options={computeGreySauda(singlePurchaseOrder).map(
                            (ele, i) => ({
                                label: `${ele.element.name} ${ele.element.description}`,
                                value: `${i}/${ele.lineItemIndex}`,
                            })
                        )}
                        onChange={(selectedOption) => {
                            const data = selectedOption.value.split('/');
                            const saudaIndex = data[0];
                            const itemIndex = data[1];
                            console.log(
                                singlePurchaseOrder,
                                'single purchase order'
                            );
                            console.log(
                                singlePurchaseOrder.line_items[itemIndex]
                                    .quantity
                            );
                            const rate =
                                singlePurchaseOrder.line_items[itemIndex].rate;
                            const remainingQuantity =
                                singlePurchaseOrder.line_items[itemIndex]
                                    .quantity;
                            console.log('hi', saudaIndex.toString());
                            formik.setFieldValue(
                                `selectedSaudaIndex`,
                                selectedOption.value
                            );
                            formik.setFieldValue(
                                `remainingOrderQuantity`,
                                remainingQuantity.toString()
                            );
                            setRate(rate);
                        }}
                    />
                    <FormikInputGroup
                        name="remainingOrderQuantity"
                        label="Remaining Quantity"
                        readOnly
                        formik={formik}
                    />
                </div>
                <div
                    className="grid grid-cols-7 gap-2"
                    onClick={(e) => {
                        e.stopPropagation();
                        console.log('check');
                    }}
                >
                    <div className="col-span-3">
                        <FormikSelectGroup
                            formik={formik}
                            name={`quality`}
                            label={'Select Quality'}
                            options={qualityData.map((ele) => ({
                                label: ele.name,
                                value: `${ele._id}/${ele?.hsnCode}`,
                            }))}
                            onChange={(selectedOption) => {
                                formik.setFieldValue(
                                    'quality',
                                    selectedOption.value
                                );
                                formik.setFieldValue(
                                    'qualityName',
                                    selectedOption.label
                                );
                                setTakaModel(true);
                            }}
                        />
                    </div>
                    <div className="col-span-1">
                        <FormikSelectGroup
                            formik={formik}
                            options={BOOLEAN}
                            name={'isOrderComplete'}
                            label="Sauda Complete"
                        />
                    </div>
                    <div className="col-span-3">
                        <FormikAsyncSelect
                            name="millVendor"
                            label="mill Vendor"
                            formik={formik}
                            getOptions={async (value) => {
                                const data = {
                                    search: value,
                                    contact_type: 'vendor',
                                };
                                const string = QueryString.stringify(data);
                                const contactsResp =
                                    await authAxiosInstance.get(
                                        `purchase-orders/contacts?${string}`
                                    );
                                const contacts =
                                    contactsResp.data.data.contacts;
                                console.log(contacts, 'cts');
                                const serverResp = contacts.map((ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_id}/${ele.contact_name}`,
                                }));
                                return serverResp;
                            }}
                            onChange={(option) => {
                                const id = option.value.split('/')[0];
                                const vendor_name = option.value.split('/')[1];
                                formik.setFieldValue(
                                    'millVendorName',
                                    vendor_name
                                );
                                formik.setFieldValue('millVendor', id);
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="mt-3">
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="poDetails"
                                render={(arrayHelpers) => {
                                    return (
                                        <>
                                            <table className="table-auto w-full">
                                                <TableHeader
                                                    headers={[
                                                        {
                                                            name: 'Sr No.',
                                                            style: {
                                                                minWidth:
                                                                    '20px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Item Name',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'HSNCode',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Taka',
                                                            style: {
                                                                minWidth:
                                                                    '20px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Meter',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Rate',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                        {
                                                            name: 'Action',
                                                            style: {
                                                                minWidth:
                                                                    '80px',
                                                            },
                                                        },
                                                    ]}
                                                />
                                                <tbody className="text-sm">
                                                    {formik.values.poDetails.map(
                                                        (ele, i) => (
                                                            <tr
                                                                key={`${ele.product}_${i}`}
                                                            >
                                                                <PoDetailRow
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    index={i}
                                                                    element={
                                                                        ele
                                                                    }
                                                                    arrayHelpers={
                                                                        arrayHelpers
                                                                    }
                                                                />
                                                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                                    <PrimaryButton
                                                                        type="button"
                                                                        onClick={() => {
                                                                            console.log(
                                                                                ele,
                                                                                'check now'
                                                                            );
                                                                            setEditData(
                                                                                {
                                                                                    ...ele,
                                                                                    curIndex:
                                                                                        i,
                                                                                }
                                                                            );
                                                                            setTimeout(
                                                                                () => {
                                                                                    setTakaModel(
                                                                                        true
                                                                                    );
                                                                                },
                                                                                200
                                                                            );
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </PrimaryButton>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    );
                                }}
                            ></FieldArray>
                        </FormikProvider>
                    </div>
                    <div className="mt-3">
                        {loading ? (
                            <ClipLoader />
                        ) : (
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ReceiveGreyPurchaseOrder;
