import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { TableHeader } from '../../components/Table/TableHeader';
import moment from 'moment';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import MillReceiveTakaPopup from './MillReceiveTakaPopup';
import { useNavigate } from 'react-router-dom';

const CreateGp = () => {
    const dispatch = useDispatch();
    const [pendingOrders, setPendingOrders] = useState([]);
    const [vendorName, setVendorName] = useState(null);
    const [vendorId, setVendorId] = useState(null);
    const [selectedChallan, setSelectedChallan] = useState([]);
    const navigate = useNavigate();

    return (
        <div className="p-4">
            <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                    <label>Select Vendor</label>
                    <AsyncSelect
                        loadOptions={async (value) => {
                            const action = await dispatch(
                                fetchContacts({
                                    search: value,
                                    contact_type: 'vendor',
                                })
                            );
                            const serverResp = action.payload.contacts.map(
                                (ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_id}/${ele.contact_name}`,
                                })
                            );
                            return serverResp;
                        }}
                        onChange={async (option) => {
                            const id = option.value.split('/')[0];
                            const vendor_name = option.value.split('/')[1];
                            setVendorId(id);
                            setVendorName(vendor_name);
                            const data = {
                                vendor: id,
                                sort: { challanNo: 1 },
                                populate: true,
                                status: {
                                    $in: ['pending', 'incomplete'],
                                },
                            };
                            const string = QueryString.stringify(data);
                            const resp = await authAxiosInstance.get(
                                `/millDispatch?${string}`
                            );
                            if (resp?.data?.data?.docs?.length) {
                                const pendingOrders = resp.data.data.docs;
                                setPendingOrders(pendingOrders);
                            } else {
                                setPendingOrders([]);
                            }
                        }}
                    />
                </div>
                <table className="w-full table-auto">
                    <TableHeader
                        headers={[
                            {
                                name: 'select',
                            },
                            {
                                name: 'Date',
                            },
                            {
                                name: 'Challan No',
                            },
                            {
                                name: 'Quality',
                            },
                        ]}
                    />
                    <tbody>
                        {pendingOrders?.map((ele, i) => (
                            <tr key={ele?._id}>
                                <td className="pl-5">
                                    <input
                                        type="checkbox"
                                        name="selectChallan"
                                        onChange={(e) => {
                                            console.log(ele._id);
                                            if (e.target.checked) {
                                                setSelectedChallan([
                                                    ...selectedChallan,
                                                    ele._id,
                                                ]);
                                            } else {
                                                const filteredChallan =
                                                    selectedChallan.filter(
                                                        (challan) => {
                                                            return (
                                                                ele._id !==
                                                                challan
                                                            );
                                                        }
                                                    );
                                                setSelectedChallan(
                                                    filteredChallan
                                                );
                                            }
                                        }}
                                        checked={selectedChallan.find(
                                            (challan) => challan._id === ele._id
                                        )}
                                    />
                                </td>
                                <td>
                                    {moment(ele.createdAt).format('DD/MM/YYYY')}
                                </td>
                                <td>{ele.challanNo}</td>
                                <td>{ele?.quality?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div>
                    <PrimaryButton
                        onClick={(e) => {
                            if (selectedChallan?.length === 0) {
                                alert('Please select atleast one challan');
                                return;
                            }
                            const binaryData = btoa(
                                JSON.stringify({
                                    vendorId,
                                    vendorName,
                                    challans: selectedChallan,
                                })
                            );
                            navigate(`/mill-receive/${binaryData}`);
                        }}
                    >
                        Add to GP
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default CreateGp;
