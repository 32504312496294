import React from 'react';

const SuccessButton = ({ children, className, type = 'button', ...props }) => {
    return (
        <button
            className={`btn bg-emerald-500 hover:bg-emerald-600 text-white ${className}`}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};

export default SuccessButton;
