import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment-timezone';
const FormikInputDateGroup = ({
    formik,
    label = '',
    required = false,
    name,
    dateFormat = 'dd/MM/yyyy',
    ...props
}) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full flex flex-col"
        >
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <DatePicker
                name={name}
                selected={moment(_.at(formik.values, name)[0])
                    .tz(process.env.REACT_APP_DEFAULT_TIMEZONE)
                    ?.valueOf()}
                onBlur={formik.handleBlur}
                onChange={(selectedDate) => {
                    formik.setFieldValue(
                        name,
                        moment(selectedDate)
                            .tz(process.env.REACT_APP_DEFAULT_TIMEZONE)
                            ?.valueOf()
                    );
                }}
                className="w-full form-input"
                dateFormat={dateFormat}
                autoComplete="off"
                dropdownMode="select"
                {...props}
            />
            {formik?.errors?.[name] && formik?.touched?.[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikInputDateGroup;
