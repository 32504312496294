export const TableHeader = ({ headers }) => {
    return (
        <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
            <tr>
                {headers.map((header, i) => (
                    <th
                        key={i}
                        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap break-words"
                    >
                        <div
                            className="font-semibold text-left break-words"
                            style={header.style}
                        >
                            {header.name}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};
