import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import QueryString from 'qs';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../app/reducers/Items/items.slice';
import { customIDs } from '../../utils/customIds';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import FormikDraggableProvider from '../../components/formik/FormikDraggableProvider';
import customId from 'custom-id';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { X } from 'react-feather';
import { Reorder } from 'framer-motion';

const FileUploadModal = ({
    isFileUploadModalOpen,
    setIsFileUploadModalOpen,
    selectedFile,
    setSelectedFile,
}) => {
    const dispatch = useDispatch();
    const fetchItems = async () => {
        const data = {
            item_id: selectedFile,
        };
        const string = QueryString.stringify(data);
        const itemResp = await authAxiosInstance.get(`items?${string}`);
        console.log(itemResp.data);
    };
    const formik = useFormik({
        initialValues: {
            productFiles: [],
            priceFiles: [],
        },

        onSubmit: (values) => {
            if (
                values.priceFiles.length === 0 ||
                values.productFiles.length === 0
            ) {
                alert('Please select atleast one file to upload');
                return;
            }
            const productString =
                values.productFiles.length > 0
                    ? btoa(JSON.stringify(values.productFiles))
                    : null;
            const priceString =
                values.priceFiles.length > 0
                    ? btoa(JSON.stringify(values.priceFiles))
                    : null;
            let item_payload = {
                item_id: selectedFile,
                custom_fields: [],
            };
            if (productString) {
                item_payload.custom_fields.push({
                    customfield_id: customIDs.item_product_files,
                    value: productString,
                });
            }

            if (priceString) {
                item_payload.custom_fields.push({
                    customfield_id: customIDs.item_price_files,
                    value: priceString,
                });
            }
            item_payload.custom_fields.push({
                value: 'https://drive.google.co/file/d/1k69C4nO1osycYbDb7_gufKksYVrXnxct/view?usp=sharing',
                customfield_id: '597392000009194464',
            });
            dispatch(updateItem(item_payload));
        },
    });
    useEffect(() => {
        if (isFileUploadModalOpen && selectedFile) {
            fetchItems();
        }
        if (!isFileUploadModalOpen) {
            setSelectedFile(null);
        }
    }, [selectedFile, isFileUploadModalOpen]);

    return (
        <ModalBasic
            modalOpen={isFileUploadModalOpen}
            setModalOpen={setIsFileUploadModalOpen}
            title={'Add Files'}
        >
            <form className="p-4" onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-2">
                    <FormikDraggableProvider
                        name={'productFiles'}
                        formik={formik}
                    >
                        <FieldArray
                            name="productFiles"
                            render={(arrayHelpers) => (
                                <>
                                    <div className="mb-4 flex flex-col gap-4 items-center">
                                        <div className="flex gap-4">
                                            <h1 className="textlg font-bold">
                                                Select Product Files
                                            </h1>
                                            <PrimaryButton
                                                type="button"
                                                onClick={() => {
                                                    arrayHelpers.push({
                                                        reOrderKey: customId(
                                                            {}
                                                        ),
                                                    });
                                                }}
                                            >
                                                Add
                                            </PrimaryButton>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            {formik.values.productFiles.map(
                                                (file, index) => (
                                                    <Reorder.Item
                                                        value={file}
                                                        key={file.reOrderKey}
                                                    >
                                                        <div className="flex gap-2 border border-slate-400 p-2">
                                                            <X
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            <FormikDirectFileUpload
                                                                formik={formik}
                                                                location={`photos/`}
                                                                randomize
                                                                name={`productFiles.${index}.value`}
                                                            />
                                                        </div>
                                                    </Reorder.Item>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        >
                            <FormikDirectFileUpload
                                name={'productFiles'}
                                formik={formik}
                                label="Product Files"
                            />
                        </FieldArray>
                    </FormikDraggableProvider>
                    <FormikDraggableProvider
                        name={'priceFiles'}
                        formik={formik}
                    >
                        <FieldArray
                            name="priceFiles"
                            render={(arrayHelpers) => (
                                <>
                                    <div className="mb-4 flex flex-col gap-4 items-center">
                                        <div className="flex gap-4">
                                            <h1 className="textlg font-bold">
                                                Select Price Files
                                            </h1>
                                            <PrimaryButton
                                                type="button"
                                                onClick={() => {
                                                    arrayHelpers.push({
                                                        reOrderKey: customId(
                                                            {}
                                                        ),
                                                    });
                                                }}
                                            >
                                                Add
                                            </PrimaryButton>
                                        </div>
                                        <div className="flex flex-col gap-2">
                                            {formik.values.priceFiles.map(
                                                (file, index) => (
                                                    <Reorder.Item
                                                        value={file}
                                                        key={file.reOrderKey}
                                                    >
                                                        <div className="flex gap-2 border border-slate-400 p-2">
                                                            <X
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            <FormikDirectFileUpload
                                                                formik={formik}
                                                                location={`photos/`}
                                                                randomize
                                                                name={`priceFiles.${index}.value`}
                                                            />
                                                        </div>
                                                    </Reorder.Item>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        ></FieldArray>
                    </FormikDraggableProvider>
                </div>
                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </ModalBasic>
    );
};

export default FileUploadModal;
