import React from 'react';

const DangerButton = ({ children, className, type = 'button', ...props }) => {
    return (
        <button
            className={`focus:border-black btn bg-rose-500 hover:bg-rose-600 text-white ${className}`}
            type={type}
            {...props}
        >
            {children}
        </button>
    );
};

export default DangerButton;
