import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { TableHeader } from '../../components/Table/TableHeader';
import moment from 'moment';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import MillReceiveTakaPopup from './MillReceiveTakaPopup';
import { useParams } from 'react-router-dom';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import * as Yup from 'yup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import {
    fetchLocations,
    getLocation,
} from '../../app/reducers/Location/locationSlice';
import { generateOptions, parseToFloat } from '../../utils/Utils';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { TDS_OPTIONS } from '../../utils/dropdownOptions';

const MillReceive = () => {
    const { gpData } = useParams();
    const dispatch = useDispatch();
    const [challanToEdit, setChallanToEdit] = useState(null);
    const { location } = useSelector(getLocation);
    const locationInfoId = localStorage.getItem('locationInfoId');
    const [millReceiveTakaPopupOpen, setMillReceiveTakaPopupOpen] =
        useState(false);
    const [loading, setLoading] = useState(false);
    const checkIncomplete = (values) => {
        for (let i = 0; i < values.poData.length; i++) {
            const poData = values.poData[i];
            for (let j = 0; j < poData.takaDetails.length; j++) {
                const taka = poData.takaDetails[j];
                if (taka.status === 'inactive') {
                    return false;
                }
            }
        }
        return true;
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            vendor: '',
            vendorName: '',
            invoiceNo: '',
            discount: '0',
            tax: '5',
            amountBeforeTax: '',
            finalAmount: '',
            notes: '',
            tds: '0_0',
            adjustment: '0',
            tdsValue: '',
            receiveLocation: locationInfoId,
            poData: [],
        },
        validationSchema: Yup.object({
            vendor: Yup.string().required(),
            vendorName: Yup.string().required(),
            invoiceNo: Yup.string().required(),
            discount: Yup.number().required(),
            tax: Yup.number().required(),
            amountBeforeTax: Yup.number().required(),
            finalAmount: Yup.number().required(),
            poData: Yup.array().of(
                Yup.object({
                    challanNo: Yup.string().required(),
                    date: Yup.string().required(),
                    lotNo: Yup.string().required(),
                    greyMt: Yup.string().required(),
                    finalMt: Yup.string().required(),
                    PCS: Yup.string().required(),
                    rate: Yup.string().required(),
                    amount: Yup.string().required(),
                    hsnCode: Yup.string().required(),
                    takaDetails: Yup.array().of(
                        Yup.object({
                            takaNo: Yup.string().required(),
                            sendQuantity: Yup.number().required(),
                        })
                    ),
                })
            ),
        }),

        onSubmit: (values) => {
            try {
                const filteredPoData = values.poData.reduce((acc, ele) => {
                    const filteredTakaDetails = ele.takaDetails.filter(
                        (item) => item.status === 'active'
                    );
                    ele.takaDetails = filteredTakaDetails;
                    acc.push(ele);
                    return acc;
                }, []);

                values.poData = filteredPoData;

                values.tds =
                    parseToFloat(values?.tds?.split('_')?.[0]) > 0
                        ? values.tds.split('_')[0]
                        : null;

                // console.log(values, 'values');
                const resp = authAxiosInstance.post(
                    '/millDispatch/receive',
                    values
                );
            } catch (error) {
                console.log(error);
                toast.error('Failed to create mill receive');
            }
        },
    });

    const computeFormik = (pos, vendorName, vendorId) => {
        formik.setFieldValue('vendor', vendorId);
        formik.setFieldValue('vendorName', vendorName);
        console.log(pos);
        console.log(vendorName);
        const poData = pos.map((ele) => {
            console.log(ele, 'po data ele');
            const takaDetails = ele.takaDetails.reduce((acc, taka) => {
                console.log(taka.status, 'status');
                if (taka.status !== 'received') {
                    acc.push({
                        takaId: taka.takaId,
                        takaNo: taka.takaNo,
                        sendQuantity: taka.sendQuantity,
                        receiveQuantity: [''],
                        returnQuantity: 0,
                        status: taka.status,
                    });
                }
                return acc;
            }, []);
            const payload = {
                poId: ele._id,
                challanNo: ele.challanNo,
                date: moment(ele.createdAt).valueOf(),
                lotNo: '',
                greyMt: '',
                finalMt: '',
                PCS: '',
                amount: '',
                hsnCode: '',
                rate: '',
                sendLocation: ele.location,
                status: 'completed',
                takaDetails: takaDetails,
            };
            return payload;
        });
        formik.setFieldValue('poData', poData);
    };

    const computeAmountBeforeTax = (data) => {
        const finalAmount = data.poData.reduce((acc, ele) => {
            const receiveTotal = parseToFloat(ele.amount);
            acc += receiveTotal;
            return acc;
        }, 0);
        console.log(data.discount);
        const netAmount =
            finalAmount - (parseToFloat(data.discount) / 100) * finalAmount;

        return netAmount > 0 ? parseToFloat(netAmount) : '';
    };

    const computePCS = (data) => {
        const totalPCS = data.takaDetails.reduce((acc, ele) => {
            if (ele.status === 'active') {
                const receiveTotal = ele.receiveQuantity.length;
                acc += receiveTotal;
            }
            return acc;
        }, 0);
        return totalPCS > 0 ? totalPCS : '';
    };
    const computeGreyMt = (data) => {
        const totalGreyMt = data.takaDetails.reduce((acc, ele) => {
            if (ele.status === 'active') {
                acc += parseToFloat(ele.sendQuantity);
            }
            return acc;
        }, 0);
        return totalGreyMt > 0 ? parseToFloat(totalGreyMt) : '';
    };

    const computeFinalMt = (data) => {
        const totalFinalMt = data.takaDetails.reduce((acc, ele) => {
            if (ele.status === 'active') {
                const receiveTotal = ele.receiveQuantity.reduce((acc, rec) => {
                    acc += Number(rec);
                    return acc;
                }, 0);
                acc += receiveTotal;
            }
            return acc;
        }, 0);
        return totalFinalMt > 0 ? totalFinalMt : '';
    };

    const fetchPos = async (data, vendorName, vendorId) => {
        console.log(data, 'data');
        const string = QueryString.stringify(data);
        const resp = await authAxiosInstance.get(`/millDispatch?${string}`);
        if (resp?.data?.data?.docs?.length) {
            const pendingOrders = resp.data.data.docs;
            computeFormik(pendingOrders, vendorName, vendorId);
        }
    };

    useEffect(() => {
        console.log(JSON.parse(atob(gpData)), 'gp data');
        const { challans, vendorName, vendorId } = JSON.parse(atob(gpData));
        console.log(challans);
        if (challans?.length <= 0) {
            alert('please select one challan from GP');
            return;
        }
        const data = {
            _id: { $in: challans },
        };

        fetchPos(data, vendorName, vendorId);
    }, [gpData]);
    useEffect(() => {
        dispatch(fetchLocations());
    }, []);

    return (
        <div className="p-4">
            <MillReceiveTakaPopup
                challanToEdit={challanToEdit}
                setChallanToEdit={setChallanToEdit}
                formik={formik}
                millReceiveTakaPopupOpen={millReceiveTakaPopupOpen}
                setMillReceiveTakaPopupOpen={setMillReceiveTakaPopupOpen}
            />
            <div className="flex flex-col gap-4">
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-4"
                >
                    <div className=" grid grid-cols-5 gap-4">
                        <FormikInputGroup
                            name={'vendorName'}
                            formik={formik}
                            label="Vendor Name"
                            readOnly
                        />
                        <FormikInputGroup
                            name={'invoiceNo'}
                            formik={formik}
                            label="Invoice No"
                        />
                        <FormikInputGroup
                            name={'discount'}
                            formik={formik}
                            label="Discount %"
                        />
                        <FormikInputGroup
                            name={'tax'}
                            formik={formik}
                            label="Tax"
                        />
                        <FormikSelectGroup
                            name={'tds'}
                            label="TDS"
                            formik={formik}
                            options={TDS_OPTIONS}
                        />
                    </div>
                    <div className="grid grid-cols-6 gap-4">
                        <FormikInputGroup
                            name={'amountBeforeTax'}
                            formik={formik}
                            label="Amount Before Tax"
                            readOnly
                            value={computeAmountBeforeTax(formik.values)}
                        />

                        <FormikInputGroup
                            name="tdsValue"
                            label="TDS %"
                            formik={formik}
                            value={formik?.values?.tds?.split('_')?.[1]}
                            readOnly
                        />
                        <FormikInputGroup
                            label="Adjustment"
                            name={'adjustment'}
                            formik={formik}
                        />
                        <FormikInputGroup
                            name={'finalAmount'}
                            formik={formik}
                            label="Final Amount"
                            readOnly
                            value={parseToFloat(
                                formik.values.amountBeforeTax -
                                    parseToFloat(
                                        (formik.values.amountBeforeTax *
                                            parseToFloat(
                                                formik.values.tdsValue
                                            )) /
                                            100
                                    ) +
                                    parseToFloat(
                                        (parseToFloat(formik.values.tax) /
                                            100) *
                                            formik.values.amountBeforeTax
                                    ) +
                                    parseToFloat(formik.values.adjustment)
                            )}
                        />
                        <FormikInputGroup
                            name={'notes'}
                            label="Notes"
                            formik={formik}
                        />
                        <FormikSelectGroup
                            name={'receiveLocation'}
                            label="Location"
                            formik={formik}
                            options={generateOptions({
                                array: location.docs,
                                labelField: 'name',
                                valueField: '_id',
                            })}
                        />
                    </div>
                    <table className="w-full table-auto">
                        <TableHeader
                            headers={[
                                {
                                    name: 'Date',
                                },
                                {
                                    name: 'Challan No',
                                },
                                {
                                    name: 'Lot No',
                                },
                                {
                                    name: 'Grey Mt',
                                },
                                {
                                    name: 'Final Mt',
                                },
                                {
                                    name: 'PCS',
                                },
                                {
                                    name: 'Rate',
                                },
                                {
                                    name: 'Amount',
                                },
                                {
                                    name: 'Action',
                                },
                            ]}
                        />
                        <tbody>
                            {formik.values.poData?.map((ele, i) => (
                                <tr key={ele?.poId}>
                                    <td>
                                        <FormikInputDateGroup
                                            name={`poData.${i}.date`}
                                            formik={formik}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.challanNo`}
                                            formik={formik}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.lotNo`}
                                            formik={formik}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.greyMt`}
                                            formik={formik}
                                            readOnly
                                            value={computeGreyMt(ele)}
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.finalMt`}
                                            formik={formik}
                                            readOnly
                                            value={computeFinalMt(ele)}
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.PCS`}
                                            formik={formik}
                                            readOnly
                                            value={computePCS(ele)}
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.rate`}
                                            formik={formik}
                                            readOnly
                                        />
                                    </td>
                                    <td>
                                        <FormikInputGroup
                                            name={`poData.${i}.amount`}
                                            formik={formik}
                                            readOnly
                                            value={ele.rate * ele.finalMt}
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            <PrimaryButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setMillReceiveTakaPopupOpen(
                                                        true
                                                    );
                                                    setChallanToEdit(i);
                                                }}
                                            >
                                                Receive
                                            </PrimaryButton>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        {/* {loading || formik.isSubmitting ? (
                            <ClipLoader />
                        ) : (
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        )} */}
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MillReceive;
