import QueryString from 'qs';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authAxiosInstance, axiosInstance } from '../../utils/axiosConfig';
import { Chart as ChartJs, Legend, registerables } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import font from '../../assets/hindi.ttf';

import {
    Document,
    Page,
    Image,
    View,
    PDFDownloadLink,
    StyleSheet,
    PDFViewer,
    Text,
    Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { parseToFloat } from '../../utils/Utils';

ChartJs.register(Legend, ChartDataLabels, ...registerables);

Font.register({
    family: 'hindi',
    src: font,
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 45,
        paddingHorizontal: 15,
    },
    title: {
        fontSize: 25,
        textAlign: 'center',
        fontFamily: 'hindi',
    },
    subTitle: {
        fontSize: 15,
        textAlign: 'center',
        fontFamily: 'hindi',
    },
    heading: {
        fontSize: 12,
        textAlign: 'center',
    },
    headingHindi: {
        fontSize: 12,
        textAlign: 'center',
        fontFamily: 'hindi',
    },
    leftHindi: {
        fontSize: 12,
        fontFamily: 'hindi',
    },
    leftHindi2: {
        fontSize: 15,
        fontFamily: 'hindi',
    },
    bar: {
        width: 300,
    },
    pie: {
        width: 300,
    },
});

const computeQualityData = (poDetails) => {
    const data = poDetails?.reduce((acc, ele) => {
        if (acc[ele.cf_quality_name]) {
            acc[ele.cf_quality_name] += parseInt(ele.cf_qty_taka);
        } else {
            acc[ele.cf_quality_name] = parseInt(ele.cf_qty_taka);
        }
        return acc;
    }, {});
    const sorted = Object.entries(data)
        .sort(([, a], [, b]) => b - a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    return {
        labels: Object.keys(sorted).slice(0, 5),
        values: Object.values(sorted).slice(0, 5),
    };
};
const computeCreditScore = (credit, from) => {
    console.log({ credit, from });
    let result = { color: '#fff', text: 'NOT CALCULATED' };
    if (credit && from) {
        console.log({ credit, from });
        const finQ =
            moment(from).quarter() - 1 === 0 ? 4 : moment(from).quarter() - 1;
        const creditScores = [];
        for (let i = 0; i < finQ; i++) {
            creditScores.push(credit[`Q${i + 1}`]);
        }
        const currentScore = credit[`Q${finQ}`];
        if (currentScore > 828) {
            result = { color: 'green', text: 'GOOD' };
        }
        if (currentScore > 756 && currentScore <= 828) {
            result = { color: 'yellow', text: 'AVERAGE' };
        }
        if (currentScore <= 756) {
            result = { color: 'red', text: 'NOT GOOD' };
        }
        return { creditScores, current: currentScore, result };
    } else {
        return { creditScores: [], current: '', result };
    }
};
const computePoData = (poDetails, isVendor) => {
    if (!poDetails) {
        return { totalTaka: 0, poList: [], totalPoAmount: 0 };
    }
    console.log(isVendor, poDetails, 'data');
    const totalTaka = poDetails
        ?.reduce((acc, ele) => (acc += parseInt(ele.cf_qty_taka)), 0)
        ?.toLocaleString('en-IN');
    const totalPoAmount = poDetails
        ?.reduce((acc, ele) => (acc += parseToFloat(ele.total)), 0)
        ?.toLocaleString('en-IN');
    const poList = poDetails.map((ele) => ({
        status: ele.status,
        quantity: ele.quantity,
        date: moment(ele.date).format('DD-MM-YYYY'),
        delivery_date: moment(ele.delivery_date).format('DD-MM-YYYY'),
        purchaseorder_number: ele.purchaseorder_number,
        vendor_name: isVendor ? ele.cf_buyer_name : ele.vendor_name,
        amount: ele.total,
        rate: ele.cf_rate,
        qty_taka: ele.cf_qty_taka,
        quality_name: ele.cf_quality_name,
    }));
    console.log(totalTaka, poList, totalPoAmount, 'data 2');
    return { totalTaka, poList, totalPoAmount };
};

const PdfDoc = ({
    img1,
    img2,
    from,
    to,
    contactDetail,
    totalTaka,
    totalPoAmount,
    currentCreditScore,
    allCreditScores,
    poCount,
    solveCount,
    result,
    poList,
    isVendor,
}) => {
    return (
        <Document>
            {!isVendor && (
                <Page style={styles.body}>
                    <Text
                        style={{
                            position: 'absolute',
                            left: 10,
                            bottom: 5,
                            fontSize: 10,
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                    <Text
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: 5,
                            fontSize: 10,
                            color: '#eee',
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${contactDetail?.contact_name}`
                        }
                        fixed
                    />
                    <View
                        style={{
                            position: 'absolute',
                            bottom: '19cm',
                            width: 10,
                            height: '1',
                            backgroundColor: 'black',
                        }}
                    ></View>
                    <View>
                        <Text style={styles.title}>ग्राहक डेटा विश्लेषण</Text>
                    </View>
                    <View>
                        <Text style={styles.subTitle}>
                            ग्राहक डेटा विश्लेषण: (व्यापार की स्थिति के बारे में
                            संक्षेप में जानकारी)
                        </Text>
                    </View>
                    <View style={{ marginTop: 10, marginBottom: 20 }}>
                        <Text style={styles.subTitle}>
                            Quarterly Sales Report
                        </Text>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 20,
                        }}
                    >
                        <Image src={img1} style={styles.bar} />
                        <View style={{ width: 200 }}>
                            <Text style={styles.leftHindi}>
                                हमारी उत्पादों की गुणवत्ता और सेवाएं उच्चतम
                                मानकों को पूरा कर रही हैं। हमने इस तीन महीनों
                                में अपने उत्पादों की वाणिज्यिकता और विश्वसनीयता
                                को बढ़ाने के लिए सुधार किए हैं। यही कारण है कि
                                हमारे ग्राहक हमेशा हमारे साथ विश्वास और संबंध
                                बनाए रखना चाहते हैं। धन्यवाद्
                            </Text>
                            <Text style={{ fontSize: 12, marginTop: 5 }}>
                                KUKREJABULLS AGENCY
                            </Text>
                        </View>
                    </View>

                    <View
                        style={{
                            marginTop: 20,
                            width: 160,
                            borderRadius: 10,
                            marginBottom: 20,
                            textAlign: 'left',
                            padding: 5,
                            borderWidth: 2,
                            borderColor: 'black',
                        }}
                    >
                        <Text
                            style={{
                                ...styles.headingHindi,
                                textDecoration: 'underline',
                                textAlign: 'left',
                            }}
                        >
                            Total Quantity Purchased
                        </Text>
                        <Text
                            style={{
                                ...styles.headingHindi,
                                textAlign: 'left',
                            }}
                        >
                            {totalTaka} TAKA
                        </Text>
                    </View>
                    <View style={{ marginTop: 10, marginBottom: 20 }}>
                        <Text style={styles.subTitle}>
                            Top 5 Qualities Purchased
                        </Text>
                    </View>
                    <View
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 5,
                        }}
                    >
                        <Image src={img2} style={styles.pie} />
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 200,
                                justifyContent: 'center',
                                gap: 20,
                            }}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text style={{ fontSize: 15 }}>Stock</Text>
                                <Text
                                    style={{
                                        fontFamily: 'hindi',
                                        fontSize: 12,
                                    }}
                                >
                                    ग्राहक इस रिपोर्ट के द्वारा अपना स्टॉक
                                    मेन्टेन कितना रखना उसका उचित निर्णय ले सकता
                                    है.
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text style={{ fontSize: 15 }}>Focus</Text>
                                <Text
                                    style={{
                                        fontFamily: 'hindi',
                                        fontSize: 12,
                                    }}
                                >
                                    ग्राहक इस रिपोर्ट के द्वारा जो क्वालिटी सबसे
                                    कम बिक रही उसपे ध्यान देकर अपनी मात्रा बड़ा
                                    सकता।
                                </Text>
                            </View>
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text style={{ fontSize: 15 }}>Marketing</Text>
                                <Text
                                    style={{
                                        fontFamily: 'hindi',
                                        fontSize: 12,
                                    }}
                                >
                                    ग्राहक इस रिपोर्ट के द्वारा क्वालिटी का सही
                                    मार्केर्टिंग कर सकता.
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 12,
                                fontFamily: 'hindi',
                                textAlign: 'center',
                                borderBottomStyle: 'dashed',
                                borderBottomColor: 'black',
                                borderBottomWidth: 2,
                            }}
                        >
                            कुकरेजा ग्रुप एक विश्वसनीय नाम है जो अपने ग्राहकों
                            के बीच विश्वसनीयता और भरोसा बनाए रखने में सक्षम है।
                        </Text>
                    </View>
                </Page>
            )}
            {!isVendor && (
                <Page style={styles.body}>
                    <Text
                        style={{
                            position: 'absolute',
                            left: 10,
                            bottom: 5,
                            fontSize: 10,
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />{' '}
                    <Text
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: 5,
                            fontSize: 10,
                            color: '#eee',
                        }}
                        render={({ pageNumber, totalPages }) =>
                            `${contactDetail?.contact_name}`
                        }
                        fixed
                    />
                    <View
                        style={{
                            position: 'absolute',
                            bottom: '19cm',
                            width: 10,
                            height: '1',
                            backgroundColor: 'black',
                        }}
                    ></View>
                    <View>
                        <Text style={styles.title}>
                            Credit Utilization Report
                        </Text>
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <View
                                style={{
                                    marginTop: 20,
                                    width: 150,
                                    borderRadius: 10,
                                    marginBottom: 20,
                                    textAlign: 'left',
                                    padding: 5,
                                    borderWidth: 2,
                                    borderColor: 'black',
                                }}
                            >
                                <Text
                                    style={{
                                        textDecoration: 'underline',
                                        textAlign: 'left',
                                        fontSize: 20,
                                        marginBottom: 10,
                                    }}
                                >
                                    Credit Score
                                </Text>
                                <Text
                                    style={{
                                        textAlign: 'left',
                                        fontSize: 20,
                                    }}
                                >
                                    {currentCreditScore}
                                </Text>
                            </View>
                            <Text>/ 900</Text>
                        </View>
                    </View>
                    <View>
                        <Text>Credit Ratio:</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    padding: 2,
                                    fontSize: 15,
                                }}
                            >
                                Q1 Credit Score
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                {allCreditScores[0]}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                }}
                            >
                                Q2 Credit Score
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    borderTopWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                {allCreditScores[1]}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                }}
                            >
                                Q3 Credit Score
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                {allCreditScores[2]}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                }}
                            >
                                Q4 Credit Score
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    borderTopWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                {allCreditScores[3]}
                            </Text>
                        </View>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={styles.leftHindi2}>
                            क्रेडिट उपयोग रिपोर्ट संदर्भित कुछ जानकारी:
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={{ ...styles.leftHindi2 }}>
                            1.क्रेडिट स्कोर को कैसे बढ़ाएं?
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                ...styles.leftHindi2,
                                fontSize: 13,
                                paddingLeft: 10,
                            }}
                        >
                            Ans: कम से कम विवर की शिकायत ओपन होने दें क्वार्टर
                            में।
                        </Text>
                        <Text
                            style={{
                                ...styles.leftHindi2,
                                fontSize: 13,
                                paddingLeft: 10,
                            }}
                        >
                            Ans:आपने क्वार्टर में जितनी बार परचेस की हो उससे 25%
                            कम पेमेंट कंप्लेंट ओपन होने दें।
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={{ fontSize: 20, marginBottom: 10 }}>
                            Your Score Report:
                        </Text>
                        <Text style={{ fontSize: 17, paddingLeft: 10 }}>
                            PO Order Count : {poCount}
                        </Text>
                        <Text style={{ fontSize: 17, paddingLeft: 10 }}>
                            Complaint Open Count : {solveCount}
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text style={{ fontSize: 20, marginBottom: 10 }}>
                            Result:{' '}
                            <Text style={{ color: result.color }}>
                                {result.text}
                            </Text>
                        </Text>
                    </View>
                    <View style={{ marginTop: 20 }}>
                        <Text style={{ ...styles.leftHindi2 }}>
                            Q: कैसे ब्रोकरेज का हिसाब निकालें स्टेटमेंट से?
                        </Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    padding: 5,
                                    fontSize: 15,
                                }}
                            >
                                QTY TAKA
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                288
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                    fontFamily: 'hindi',
                                }}
                            >
                                स्टेटमेंट देखें जो हमने आपको भेजा है।
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                }}
                            >
                                Quantity Meter
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    borderTopWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                120.25
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    borderTopWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                    fontFamily: 'hindi',
                                }}
                            >
                                विवर का चलान देखें विवेर ने जो आपको डिलीवरी
                                करवाई है
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                }}
                            >
                                Rate
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                18.00
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                    fontFamily: 'hindi',
                                }}
                            >
                                स्टेटमेंट मैं रेट देखें.
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 150,

                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: 20,
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                }}
                            >
                                Brokerage
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                }}
                            >
                                1%
                            </Text>
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    padding: 5,
                                    fontSize: 15,
                                    height: 30,
                                    fontFamily: 'hindi',
                                }}
                            >
                                {/* Brokerage */}
                            </Text>
                        </View>
                    </View>
                    <Text style={{ marginTop: 5 }}>
                        Calculation : 288*120.25*18*1%=6233/-Rs
                    </Text>
                    <View style={{ marginTop: 20 }}>
                        <Text style={{ ...styles.leftHindi2 }}>
                            Q: विवर की फर्म के नाम काफी हैं और आपने विवर के
                            ग्रुप नाम से हिसाब भेजा है,हिसाब नहीं मिल रहा है।
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                ...styles.leftHindi2,
                                fontSize: 13,
                                paddingLeft: 10,
                            }}
                        >
                            1.ग्रुप का नाम व्हाट्सएप करें, हम सभी चालानों के नाम
                            भेजेंगे।
                        </Text>
                        <Text
                            style={{
                                ...styles.leftHindi2,
                                fontSize: 13,
                                paddingLeft: 10,
                            }}
                        >
                            2.मोबाइल नंबर दे सकते हैं,जो सभी चालान पर आपको एक ही
                            मिलेगा,नाम अनेक हो सकते हैं।
                        </Text>
                    </View>
                    <View
                        style={{
                            marginTop: 20,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 12,
                                fontFamily: 'hindi',
                                textAlign: 'center',
                                borderBottomStyle: 'dashed',
                                borderBottomColor: 'black',
                                borderBottomWidth: 2,
                            }}
                        >
                            कृपया शाम को 5 से 6.30 के बीच हमें कॉल करें जानकारी
                            के लिए।
                        </Text>
                    </View>
                </Page>
            )}

            <Page style={{ ...styles.body, paddingBottom: 41 }}>
                <Text
                    style={{
                        position: 'absolute',
                        left: 10,
                        bottom: 5,
                        fontSize: 10,
                    }}
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                    }
                    fixed
                />{' '}
                <Text
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 5,
                        fontSize: 10,
                        color: '#eee',
                    }}
                    render={({ pageNumber, totalPages }) =>
                        `${contactDetail?.contact_name}`
                    }
                    fixed
                />
                <View
                    fixed
                    style={{
                        position: 'absolute',
                        bottom: '19cm',
                        width: 10,
                        height: '1',
                        backgroundColor: 'black',
                    }}
                ></View>
                <View style={{ height: 38 }}>
                    <Text style={styles.title}>Purchase Statement</Text>
                    <Text
                        style={{
                            ...styles.subTitle,
                            marginTop: 5,
                            marginBottom: 10,
                            fontSize: 10,
                        }}
                    >
                        {from} to {to}
                    </Text>
                </View>
                <View style={{ height: 20 }}>
                    <Text style={{ ...styles.subTitle, textAlign: 'left' }}>
                        Total Amount : {totalPoAmount} Total PO :{' '}
                        {poList?.length}
                    </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    <View
                        fixed
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Status
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Date
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Delivery
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                {isVendor ? 'Customer' : 'Vendor'}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                PO#
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Amount
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Taka
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Quality
                            </Text>
                        </View>
                        <View
                            style={{
                                width: 70,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Text
                                style={{
                                    borderColor: 'black',
                                    borderWidth: 2,
                                    borderLeftWidth: 0,
                                    padding: 2,
                                    fontSize: 15,
                                    height: 35,
                                }}
                            >
                                Rate
                            </Text>
                        </View>
                    </View>

                    {poList?.map((ele, i) => (
                        <View
                            key={i}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderTopWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.status}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.date}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderLeftWidth: 0,
                                        borderTopWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.delivery_date}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderLeftWidth: 0,
                                        borderTopWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.vendor_name}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.purchaseorder_number}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderLeftWidth: 0,
                                        borderTopWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {parseToFloat(ele.amount).toFixed(2)}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.qty_taka}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.quality_name}
                                </Text>
                            </View>
                            <View
                                style={{
                                    width: 70,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Text
                                    style={{
                                        borderColor: 'black',
                                        borderWidth: 2,
                                        borderTopWidth: 0,
                                        borderLeftWidth: 0,
                                        padding: 2,
                                        fontSize: 10,
                                        height: 35,
                                    }}
                                >
                                    {ele.rate}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View style={{ height: 20, marginTop: 10 }}>
                    <Text style={{ ...styles.subTitle, textAlign: 'left' }}>
                        Total Amount : {totalPoAmount} Total PO :{' '}
                        {poList?.length}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

const PoReport2 = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramsData = {};
    for (const [key, value] of queryParams) {
        paramsData[key] = value;
    }
    const [poInfo, setPoInfo] = useState(null);
    const [barImage, setBarImage] = useState(null);
    const [pieImage, setPieImage] = useState(null);
    const barRef = useRef();
    const pieRef = useRef();
    const fetchPoData = async (params) => {
        try {
            let poUrlData = {
                from: params.from,
                to: params.to,
                customer: params.customer,
            };
            let poString = QueryString.stringify(poUrlData);
            const resp = await authAxiosInstance.get(
                `purchase-orders/po/report?${poString}`
            );
            setPoInfo(resp.data?.data);
        } catch (error) {
            console.log(error);
            toast.error('Failed to fetch Data');
        }
    };
    useEffect(() => {
        console.log(paramsData);
        fetchPoData(paramsData);
    }, []);
    useEffect(() => {
        console.log('poInfo', poInfo);
    }, [poInfo]);

    return (
        <div
            style={{
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div className="absolute opacity-0 w-[500px]">
                <Bar
                    ref={barRef}
                    data={{
                        labels: poInfo?.invoiceDetail?.map
                            ? poInfo.invoiceDetail?.map((ele) =>
                                  moment(ele.date).format('MMM-YYYY')
                              )
                            : [],
                        datasets: [
                            {
                                data: poInfo?.invoiceDetail?.map
                                    ? poInfo.invoiceDetail?.map(
                                          (ele) => ele.total
                                      )
                                    : [],
                                borderWidth: 1,
                                barThickness: 55,
                                borderRadius: 10,
                                backgroundColor: '#66BA82',
                            },
                        ],
                    }}
                    options={{
                        events: [],
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: 'Date',
                                },
                            },
                            y: {
                                title: {
                                    display: true,
                                    text: 'Total Brokerage',
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            datalabels: {
                                color: '#fff',
                                formatter: (value) => {
                                    return Math.round(value).toLocaleString(
                                        'en-IN'
                                    );
                                },
                            },
                        },
                        animation: {
                            duration: 100,
                            onComplete: () => {
                                const image =
                                    barRef?.current?.toBase64Image?.();
                                setBarImage(image);
                            },
                        },
                    }}
                />
            </div>
            <div className="absolute opacity-0 w-[500px]">
                <Pie
                    ref={pieRef}
                    data={{
                        // labels: poInfo?.invoiceDetail?.map
                        //   ? poInfo.invoiceDetail.map((ele) =>
                        //       moment(ele.date).format("MMMM YYYY")
                        //     )
                        //   : [],
                        labels: computeQualityData(
                            poInfo?.poDetails ? poInfo.poDetails : []
                        ).labels,
                        datasets: [
                            {
                                data: computeQualityData(
                                    poInfo?.poDetails ? poInfo.poDetails : []
                                ).values,
                                backgroundColor: [
                                    '#f7d1aa',
                                    '#77ddb5',
                                    '#f29bb9',
                                    '#b693bf',
                                    '#82bdd6',
                                ],
                            },
                        ],
                    }}
                    options={{
                        events: [],
                        plugins: {
                            // legend: {
                            //   display: true,
                            //   position: "right",
                            // },
                            legend: {
                                position: 'bottom',
                                align: 'start',
                                labels: {
                                    font: {
                                        size: 15,
                                    },
                                    generateLabels: (chart) => {
                                        const datasets = chart.data.datasets;
                                        return datasets[0].data.map(
                                            (data, i) => ({
                                                text: `${chart.data.labels[i]} [${data} TAKA]`,
                                                fillStyle:
                                                    datasets[0].backgroundColor[
                                                        i
                                                    ],

                                                index: i,
                                            })
                                        );
                                    },
                                },
                            },
                            datalabels: {
                                display: false,
                                // color: "#fff",
                                // // formatter: function (value, context) {
                                // //   return `${value} TAKA \n ${
                                // //     context.chart.data.labels[context.dataIndex]
                                // //   }`;
                                // // },
                            },
                        },
                        animation: {
                            duration: 100,
                            onComplete: () => {
                                const image =
                                    pieRef?.current?.toBase64Image?.();
                                setPieImage(image);
                            },
                        },
                    }}
                />
            </div>
            {barImage && pieImage ? (
                <div className="relative">
                    <PDFViewer className="w-[595px] h-[842px]">
                        <PdfDoc
                            img1={barImage}
                            isVendor={poInfo?.isVendor}
                            img2={pieImage}
                            from={moment(paramsData.from).format('DD/MM/YYYY')}
                            to={moment(paramsData.to).format('DD/MM/YYYY')}
                            totalTaka={
                                computePoData(
                                    poInfo?.poDetails,
                                    poInfo?.isVendor
                                ).totalTaka
                            }
                            totalPoAmount={
                                computePoData(
                                    poInfo?.poDetails,
                                    poInfo?.isVendor
                                ).totalPoAmount
                            }
                            poList={
                                computePoData(
                                    poInfo?.poDetails,
                                    poInfo?.isVendor
                                ).poList
                            }
                            currentCreditScore={
                                computeCreditScore(
                                    poInfo?.customerCredit,
                                    paramsData?.to
                                ).current
                            }
                            allCreditScores={
                                computeCreditScore(
                                    poInfo?.customerCredit,
                                    paramsData.to
                                ).creditScores
                            }
                            result={
                                computeCreditScore(
                                    poInfo?.customerCredit,
                                    paramsData.to
                                ).result
                            }
                            poCount={poInfo?.poCount}
                            solveCount={poInfo?.solveCount}
                            contactDetail={poInfo?.contactDetail}
                        />
                    </PDFViewer>

                    <PDFDownloadLink
                        document={
                            <PdfDoc
                                img1={barImage}
                                isVendor={poInfo?.isVendor}
                                img2={pieImage}
                                from={moment(paramsData.from).format(
                                    'DD/MM/YYYY'
                                )}
                                to={moment(paramsData.to).format('DD/MM/YYYY')}
                                totalTaka={
                                    computePoData(
                                        poInfo?.poDetails,
                                        poInfo?.isVendor
                                    ).totalTaka
                                }
                                totalPoAmount={
                                    computePoData(
                                        poInfo?.poDetails,
                                        poInfo?.isVendor
                                    ).totalPoAmount
                                }
                                poList={
                                    computePoData(
                                        poInfo?.poDetails,
                                        poInfo?.isVendor
                                    ).poList
                                }
                                currentCreditScore={
                                    computeCreditScore(
                                        poInfo?.customerCredit,
                                        paramsData?.to
                                    ).current
                                }
                                allCreditScores={
                                    computeCreditScore(
                                        poInfo?.customerCredit,
                                        paramsData.to
                                    ).creditScores
                                }
                                result={
                                    computeCreditScore(
                                        poInfo?.customerCredit,
                                        paramsData.to
                                    ).result
                                }
                                poCount={poInfo?.poCount}
                                solveCount={poInfo?.solveCount}
                                contactDetail={poInfo?.contactDetail}
                            />
                        }
                        fileName={`poPdfData`}
                        className="absolute top-0 left-0 hidden md:block"
                        id="download-Pdf"
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? (
                                <PrimaryButton disabled={true}>
                                    loadingData
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton>Download</PrimaryButton>
                            )
                        }
                    </PDFDownloadLink>
                </div>
            ) : (
                <ClipLoader />
            )}
        </div>
    );
};

export default PoReport2;
