import { generateOptions } from '../../utils/Utils';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchLocations,
    getLocation,
} from '../../app/reducers/Location/locationSlice';
import { useFormik } from 'formik';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { Link } from 'react-router-dom';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';

function Dashboard() {
    const dispatch = useDispatch();
    const { location } = useSelector(getLocation);
    const { quality } = useSelector(getQuality);
    const locationInfoId = localStorage.getItem('locationInfoId');
    const [qualityData, setQualityData] = useState([]);
    const [millData, setMillData] = useState([]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            location: locationInfoId ? [locationInfoId] : [],
            quality: [],
        },
        onSubmit: async (values) => {
            // formik.resetForm()
        },
    });

    useEffect(async () => {
        const string = QueryString.stringify({
            populate: true,
            location: formik.values.location,
            quality: formik.values.quality,
            limit: 300,
        });
        const millString = QueryString.stringify({
            populate: true,
            location: formik.values.location,
            limit: 300,
        });
        const result = await authAxiosInstance.get(`inventory?${string}`);
        const millResp = await authAxiosInstance.get(
            `inventory/mill?${millString}`
        );
        console.log(millResp.data, 'result');
        if (result) {
            setQualityData(result?.data?.data?.docs);
        }
        if (millResp?.data?.data?.docs?.length) {
            setMillData(millResp?.data?.data?.docs);
        }
    }, [JSON.stringify(formik.values)]);

    useEffect(() => {
        dispatch(fetchLocations());
        dispatch(fetchQualities());
    }, []);

    const qualityMemo = useMemo(() => quality.docs ?? [], [quality.docs]);

    const colsMemo = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },

        {
            Header: 'Vendor Name',
            accessor: 'master_grey_vendor_name',
            Cell: ({ row }) => {
                const linkBinaryEncoded = btoa(
                    JSON.stringify({
                        location: formik.values.location,
                        master_grey_vendor_id:
                            row.original._id.master_grey_vendor_id,
                    })
                );
                return (
                    <Link
                        className="text-indigo-600 underline"
                        to={`/dashboard/vendor-inventory/${linkBinaryEncoded}`}
                    >
                        {row.original.master_grey_vendor_name}
                    </Link>
                );
            },
        },
        {
            Header: 'Location',
            accessor: 'location.name',
        },
        {
            Header: 'greyReceived',
            Cell: ({ row }) => {
                return <div>{row.original['stagesMap']['greyReceived']}</div>;
            },
        },
    ];

    const millColsMemo = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + 1;
            },
        },

        {
            Header: 'mill Vendor Name',
            accessor: 'vendorName',
            Cell: ({ row }) => {
                const linkBinaryEncoded = btoa(
                    JSON.stringify({
                        location: formik.values.location,
                        vendor: row.original._id.vendor,
                    })
                );
                return (
                    <Link
                        className="text-indigo-600 underline"
                        to={`/dashboard/mill/vendor-inventory/${linkBinaryEncoded}`}
                    >
                        {row.original.vendorName}
                    </Link>
                );
            },
        },
        {
            Header: 'Location',
            accessor: 'location.name',
        },
        {
            Header: 'Mill Pending',
            Cell: ({ row }) => {
                return <div>{row.original['stagesMap']['millPending']}</div>;
            },
        },
        {
            Header: 'Mill Received',
            Cell: ({ row }) => {
                return <div>{row.original['stagesMap']['millReceived']}</div>;
            },
        },
    ];

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Dashboard
                </h1>
            </div>
            <div className="flex gap-4">
                <FormikMultiSelect
                    formik={formik}
                    label="Location"
                    onChange={async (selectedOption) => {
                        const data = {
                            location: {
                                $in: selectedOption.map((ele) => ele.value),
                            },
                        };

                        // const string = QueryString.stringify(data);

                        // const productData = await authAxiosInstance.get(
                        //     `productInventory/catalog?${string}`
                        // );
                        // if (productData) {
                        //     setProductInventoryData(
                        //         productData.data?.data?.docs
                        //     );
                        // }
                        formik.setFieldValue(
                            `location`,
                            selectedOption.map((ele) => ele.value)
                        );
                    }}
                    name="location"
                    required
                    options={generateOptions({
                        array: location ? location.docs : [],
                        valueField: '_id',
                        labelField: 'name',
                    })}
                />
                <FormikMultiSelect
                    formik={formik}
                    label="Quality"
                    onChange={async (selectedOption) => {
                        formik.setFieldValue(
                            `quality`,
                            selectedOption.map((ele) => ele.value)
                        );
                    }}
                    name="quality"
                    required
                    options={generateOptions({
                        array: qualityMemo,
                        valueField: '_id',
                        labelField: 'name',
                    })}
                />
            </div>
            <div className="grid grid-cols-4 gap-4 mt-2 mb-2">
                <div
                    className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
                    style={{ width: '250px' }}
                >
                    <div className="px-3 pt-3 mb-5">
                        <header className="text-xl font-bold">
                            Total Grey Purchase
                        </header>
                        <div className="flex items-center justify-evenly px-1 pt-3">
                            0
                        </div>
                    </div>
                </div>
            </div>
            <TableWithHeadingAndSearch
                heading="Grey Inventory"
                data={qualityData}
                columns={colsMemo}
            />
            <div className="mt-3">
                <TableWithHeadingAndSearch
                    heading={'Mill Inventory'}
                    data={millData}
                    columns={millColsMemo}
                />
            </div>
        </div>
    );
}

export default Dashboard;
