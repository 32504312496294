import QueryString from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { authAxiosInstance } from '../../../utils/axiosConfig';
import { toast } from 'react-toastify';
import PageWithCard from '../../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../../components/Table/TableWithHeadingAndSearch';

const BillWiseMillInventory = () => {
    const { billString } = useParams();
    const { billNo, location, vendor, batchNo } = JSON.parse(atob(billString));
    const [loading, setLoading] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);

    useEffect(() => {
        const fetchInventory = async () => {
            try {
                setLoading(true);
                const string = QueryString.stringify({
                    billNo,
                    location,
                    batchNo,
                    vendor,
                });

                const resp = await authAxiosInstance.get(
                    `/inventory/mill/byBillNo?${string}`
                );
                setInventoryData(resp.data.data.docs);
            } catch (error) {
                console.log(error);
                toast.error('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };
        fetchInventory();
    }, [billString]);

    const cols = useMemo(() => {
        return [
            {
                Header: 'Taka#',
                accessor: 'takaNo',
            },
            {
                Header: 'Batch#',
                accessor: 'batchNo',
            },
            {
                Header: 'location',
                accessor: 'location.name',
            },
            {
                Header: 'Mill Pending',
                Cell: ({ row }) => {
                    return (
                        <div>{row.original['stagesMap']['millPending']}</div>
                    );
                },
            },
            {
                Header: 'Mill Received',
                Cell: ({ row }) => {
                    return (
                        <div>{row.original['stagesMap']['millReceived']}</div>
                    );
                },
            },
        ];
    }, [billString]);
    return (
        <PageWithCard
            heading={`${loading ? 'Loading...' : inventoryData?.[0]?.vendorName}`}
        >
            <TableWithHeadingAndSearch
                heading={'Inventory'}
                columns={cols}
                data={inventoryData}
            />
        </PageWithCard>
    );
};

export default BillWiseMillInventory;
