import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import moment from 'moment';
import { parseToFloat } from '../../utils/Utils';

const DisplayData = ({ item, data }) => {
    let [expandZoho, setExpandZoho] = useState(false);
    let [expandSystem, setExpandSystem] = useState(false);

    const TableHeader = ({ headers }) => {
        return (
            <div className="text-xs uppercase text-black grid grid-cols-6 gap-4">
                {headers?.map((header) => (
                    <div className="px-2 first:pl-14 last:pr-5 py-2 whitespace-nowrap">
                        <div className="font-bold">{header}</div>
                    </div>
                ))}
            </div>
        );
    };

    const TableHeaderSystem = ({ headers }) => {
        return (
            <div className="text-xs uppercase text-black grid grid-cols-4 gap-4">
                {headers?.map((header) => (
                    <div className="px-2 first:pl-14 last:pr-5 py-2 whitespace-nowrap">
                        <div className="font-bold">{header}</div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <div className="grid grid-cols-4 gap-4">
                <div className="px-2 first:pl-5 last:pr-5 py-2">{item}</div>
                <div className="px-2 first:pl-5 last:pr-5 py-2 flex gap-3">
                    <div>{data.zohoTotal ? data?.zohoTotal : 0}</div>
                    {expandZoho ? (
                        <ChevronDown
                            className="cursor-pointer"
                            onClick={(e) => {
                                setExpandZoho(false);
                            }}
                        />
                    ) : (
                        <ChevronRight
                            className="cursor-pointer"
                            onClick={(e) => {
                                setExpandZoho(true);
                            }}
                        />
                    )}
                </div>
                <div className="px-2 first:pl-5 last:pr-5 py-2 flex gap-3">
                    <div>{data.systemTotal ? data?.systemTotal : 0}</div>
                    {expandSystem ? (
                        <ChevronDown
                            className="cursor-pointer"
                            onClick={(e) => {
                                setExpandSystem(false);
                            }}
                        />
                    ) : (
                        <ChevronRight
                            className="cursor-pointer"
                            onClick={(e) => {
                                setExpandSystem(true);
                            }}
                        />
                    )}
                </div>

                <div className="px-2 first:pl-5 last:pr-5 py-2 flex gap-3">
                    <div>
                        {parseToFloat(data?.zohoTotal ? data.zohoTotal : 0) -
                            parseToFloat(
                                data?.systemTotal ? data.systemTotal : 0
                            )}
                    </div>
                </div>
            </div>
            {expandZoho && (
                <>
                    <div className="px-10 first:pl-14 font-semibold py-1">
                        Zoho Entries
                    </div>
                    <TableHeader
                        headers={[
                            'Company Name',
                            'Date',
                            'PO-#',
                            'Qty Taka',
                            'Quality Name',
                            'Brokerage',
                        ]}
                    />
                    {data?.zohoEntries &&
                        data.zohoEntries.length > 0 &&
                        data.zohoEntries.map((ele) => (
                            <div className="grid grid-cols-6 gap-4">
                                <div className="px-2 first:pl-14 last:pr-5 py-2">
                                    {ele.company_name}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-2">
                                    {ele.date
                                        ? moment(ele.date).format('DD-MM-YYYY')
                                        : '---'}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-2">
                                    {ele.purchaseorder_number}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-2">
                                    {ele.cf_qty_taka}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-2">
                                    {ele.cf_quality_name}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-2">
                                    {ele.total}
                                </div>
                            </div>
                        ))}
                </>
            )}
            {expandSystem && (
                <>
                    <div className="px-10 first:pl-14 font-semibold py-1">
                        System Entries
                    </div>
                    <TableHeaderSystem
                        headers={[
                            'Date',
                            'Chalan Name',
                            'Final Amount',
                            'Brockrage',
                        ]}
                    />
                    {data?.systemEntries &&
                        data.systemEntries.length > 0 &&
                        data.systemEntries.map((ele) => (
                            <div className="grid grid-cols-4 gap-4">
                                <div className="px-2 first:pl-14 last:pr-5 py-1">
                                    {ele.date ? ele.date : '---'}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-1">
                                    {ele.chalan}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-1">
                                    {ele.finalAmount}
                                </div>
                                <div className="px-2 first:pl-14 last:pr-5 py-1">
                                    {ele.brockrage}
                                </div>
                            </div>
                        ))}
                </>
            )}
        </>
    );
};

export default DisplayData;
