import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { TableHeader } from '../../components/Table/TableHeader';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SuccessButton from '../../components/infrastructure/Buttons/SuccessButton';
import { parseToFloat } from '../../utils/Utils';

const MillReceiveTakaPopup = ({
    challanToEdit,
    setChallanToEdit,
    formik,
    millReceiveTakaPopupOpen,
    setMillReceiveTakaPopupOpen,
}) => {
    useEffect(() => {
        console.log('rerender');
    }, [challanToEdit]);
    const computeShortage = (sendQuantity, receiveQuantity, returnQuantity) => {
        if (receiveQuantity?.length && sendQuantity) {
            //parseto float is added in utils and should be used to limit decimals to 2
            const totalReceive = receiveQuantity.reduce(
                (acc, ele) => (acc += parseToFloat(ele)),
                0
            );
            console.log(totalReceive + parseToFloat(returnQuantity));
            const finalQuantity = totalReceive;
            const effectiveSendQuantity =
                parseToFloat(sendQuantity) - parseToFloat(returnQuantity);

            const shortagePercentage =
                effectiveSendQuantity > 0
                    ? 100 - (finalQuantity / effectiveSendQuantity) * 100
                    : 0;
            return parseToFloat(shortagePercentage);
        }
        return null;
    };
    const handleKeyDown = ({ event, arrayHelper, receiveIndex, takaIndex }) => {
        console.log(event);
        event.stopPropagation();
        if (event.key === '+') {
            event.preventDefault();
            arrayHelper.push('');
        }

        if (event.key === 'Backspace') {
            const receiveValue =
                formik?.values?.poData?.[challanToEdit]?.takaDetails?.[
                    takaIndex
                ]?.receiveQuantity?.[receiveIndex];
            console.log(receiveValue, 'value');
            console.log(receiveIndex);
            console.log(receiveIndex > 0);
            if (!receiveValue && receiveIndex > 0) {
                event.preventDefault();
                arrayHelper.remove(receiveIndex);
            }
        }
    };
    return (
        <ModalBasic
            setModalOpen={setMillReceiveTakaPopupOpen}
            modalOpen={millReceiveTakaPopupOpen}
            title={'Receive'}
            max_width="max-w-3xl"
            ignoreClick={true}
        >
            <div className="p-4 flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4">
                    <FormikInputGroup
                        formik={formik}
                        name={`poData.${challanToEdit}.challanNo`}
                        readOnly
                        label="Challan No"
                    />
                    <FormikInputGroup
                        formik={formik}
                        name={`poData.${challanToEdit}.lotNo`}
                        label="lot No"
                    />
                    <FormikInputGroup
                        formik={formik}
                        name={`poData.${challanToEdit}.hsnCode`}
                        label="HSN Code"
                    />
                    <FormikInputGroup
                        formik={formik}
                        name={`poData.${challanToEdit}.rate`}
                        label="Rate"
                    />
                </div>
                <table className="w-full table-auto">
                    <TableHeader
                        headers={[
                            {
                                name: 'Taka No',
                            },
                            {
                                name: 'Send Quantity',
                            },
                            {
                                name: 'Receive Quantity',
                            },
                            {
                                name: 'Return Quantity',
                            },
                            {
                                name: 'Shortage %',
                            },
                            {
                                name: 'Action',
                            },
                        ]}
                    />
                    <tbody>
                        <FormikProvider value={formik}>
                            <FieldArray
                                name={`poData.${challanToEdit}.takaDetails`}
                                render={(arrayHelper) => (
                                    <>
                                        {formik?.values?.poData?.[
                                            challanToEdit
                                        ]?.takaDetails?.map((ele, i) => (
                                            <tr key={ele.id}>
                                                <td>
                                                    <FormikInputGroup
                                                        name={`poData.${challanToEdit}.takaDetails.${i}.takaNo`}
                                                        formik={formik}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <FormikInputGroup
                                                        name={`poData.${challanToEdit}.takaDetails.${i}.sendQuantity`}
                                                        formik={formik}
                                                        readOnly
                                                    />
                                                </td>
                                                <td>
                                                    <FormikProvider
                                                        value={formik}
                                                    >
                                                        <FieldArray
                                                            name={`poData.${challanToEdit}.takaDetails.${i}.receiveQuantity`}
                                                            render={(
                                                                receiveHelper
                                                            ) => {
                                                                return (
                                                                    <div className="flex gap-2">
                                                                        {formik?.values?.poData?.[
                                                                            challanToEdit
                                                                        ]?.takaDetails?.[
                                                                            i
                                                                        ]?.receiveQuantity?.map(
                                                                            (
                                                                                rec,
                                                                                j
                                                                            ) => (
                                                                                <FormikInputGroup
                                                                                    fullWidth={
                                                                                        true
                                                                                    }
                                                                                    name={`poData.${challanToEdit}.takaDetails.${i}.receiveQuantity.${j}`}
                                                                                    formik={
                                                                                        formik
                                                                                    }
                                                                                    readOnly={
                                                                                        formik
                                                                                            .values
                                                                                            .poData[
                                                                                            challanToEdit
                                                                                        ]
                                                                                            .takaDetails[
                                                                                            i
                                                                                        ]
                                                                                            .status !==
                                                                                        'active'
                                                                                    }
                                                                                    onKeyDown={(
                                                                                        e
                                                                                    ) => {
                                                                                        handleKeyDown(
                                                                                            {
                                                                                                event: e,
                                                                                                arrayHelper:
                                                                                                    receiveHelper,
                                                                                                receiveIndex:
                                                                                                    j,
                                                                                                takaIndex:
                                                                                                    i,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )
                                                                        )}
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </FormikProvider>
                                                </td>
                                                <td>
                                                    <FormikInputGroup
                                                        name={`poData.${challanToEdit}.takaDetails.${i}.returnQuantity`}
                                                        formik={formik}
                                                        readOnly={
                                                            formik.values
                                                                .poData[
                                                                challanToEdit
                                                            ].takaDetails[i]
                                                                .status !==
                                                            'active'
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <FormikInputGroup
                                                        name={`poData.${challanToEdit}.takaDetails.${i}.shortage`}
                                                        formik={formik}
                                                        readOnly
                                                        value={computeShortage(
                                                            formik.values
                                                                ?.poData?.[
                                                                challanToEdit
                                                            ].takaDetails[i]
                                                                .sendQuantity,
                                                            formik.values
                                                                ?.poData?.[
                                                                challanToEdit
                                                            ].takaDetails[i]
                                                                .receiveQuantity,
                                                            formik.values
                                                                ?.poData?.[
                                                                challanToEdit
                                                            ].takaDetails[i]
                                                                .returnQuantity
                                                        )}
                                                    />
                                                </td>
                                                <td>
                                                    {formik.values.poData[
                                                        challanToEdit
                                                    ].takaDetails[i].status ===
                                                    'active' ? (
                                                        <div>
                                                            <DangerButton
                                                                onClick={() => {
                                                                    formik.setFieldValue(
                                                                        `poData.${challanToEdit}.takaDetails.${i}.status`,
                                                                        'inactive'
                                                                    );
                                                                }}
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <SuccessButton
                                                                onClick={() => {
                                                                    formik.setFieldValue(
                                                                        `poData.${challanToEdit}.takaDetails.${i}.status`,
                                                                        'active'
                                                                    );
                                                                }}
                                                            >
                                                                Add
                                                            </SuccessButton>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            />
                        </FormikProvider>
                    </tbody>
                </table>
                <div className="grid gap-2 grid-cols-2">
                    <FormikInputGroup
                        name={`poData.${challanToEdit}.finalMt`}
                        formik={formik}
                        label="Total Mt"
                        readOnly
                    />
                    <FormikInputGroup
                        name={`poData.${challanToEdit}.PCS`}
                        formik={formik}
                        label="Total PCS"
                        readOnly
                    />
                </div>
                <div>
                    <PrimaryButton
                        type="button"
                        onClick={() => {
                            for (
                                let i = 0;
                                i <
                                formik.values.poData[challanToEdit].takaDetails
                                    .length;
                                i++
                            ) {
                                const status =
                                    formik.values.poData[challanToEdit]
                                        .takaDetails[i].status;
                                const receiveArray =
                                    formik.values.poData[challanToEdit]
                                        .takaDetails[i].receiveQuantity;
                                if (status === 'active') {
                                    for (
                                        let j = 0;
                                        j < receiveArray.length;
                                        j++
                                    ) {
                                        const element = receiveArray[j];
                                        if (!element) {
                                            alert(
                                                'Please fill add taka or remove necessary taka'
                                            );
                                            return;
                                        }
                                    }
                                } else {
                                    formik.setFieldValue(
                                        `poData.${challanToEdit}.status`,
                                        'pending'
                                    );
                                }
                            }
                            setMillReceiveTakaPopupOpen(false);
                        }}
                    >
                        Submit
                    </PrimaryButton>
                </div>
            </div>
        </ModalBasic>
    );
};

export default MillReceiveTakaPopup;
