import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import AsyncSelect from 'react-select/async';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import {
    fetchLocations,
    getLocation,
} from '../../app/reducers/Location/locationSlice';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import { REMINDER_TYPE_OPTION } from '../../utils/dropdownOptions';
import { TableHeader } from '../../components/Table/TableHeader';
import { MillDispatchRow } from './MillDispatchRow';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import EditTakaModal from './EditTakaModal';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { getMillDispatch } from '../../app/reducers/MillDispatch/millDispatchSlice';
import { createMillDispatch } from '../../app/api/millDispatch';
const MillDispatch = () => {
    const dispatch = useDispatch();
    const { location } = useSelector(getLocation);
    const locationInfoId = localStorage.getItem('locationInfoId');
    const [editTakaDetailsModalOpen, setEditTakaDetailsModalOpen] =
        useState(false);
    const [selectedBatchForEdit, setSelectedBatchForEdit] = useState(null);
    const { loading } = useSelector(getMillDispatch);

    useEffect(() => {
        dispatch(fetchLocations());
    }, []);

    const formik = useFormik({
        initialValues: {
            vendor: '',
            vendorName: '',
            location: locationInfoId ? locationInfoId : '',
            batches: [],
        },
        validationSchema: Yup.object({
            vendor: Yup.string().required(),
            vendorName: Yup.string().required(),
            location: Yup.string().required(),
            batches: Yup.array().of(
                Yup.object({
                    batchNo: Yup.string().required(),
                    takaDetails: Yup.array(),
                    quality: Yup.string(),
                    totalMeter: Yup.number(),
                    totalTaka: Yup.number(),
                })
            ),
        }),
        onSubmit: async (values) => {
            // console.log(values.batches[0].takaDetails[0].status, 'values');
            const filteredBatches = values.batches.reduce((acc, ele) => {
                const filteredTakaDetails = ele.takaDetails.filter(
                    (item) => item.status === 'active'
                );
                ele.takaDetails = filteredTakaDetails;
                acc.push(ele);
                return acc;
            }, []);
            values.batches = filteredBatches;
            // dispatch(createMillDispatch(values));
            const dispatchResp = await createMillDispatch(values);
        },
        enableReinitialize: true,
    });
    return (
        <div className="p-4">
            <EditTakaModal
                editTakaDetailsModalOpen={editTakaDetailsModalOpen}
                selectedBatchForEdit={selectedBatchForEdit}
                setEditTakaDetailsModalOpen={setEditTakaDetailsModalOpen}
                setSelectedBatchForEdit={setSelectedBatchForEdit}
                formik={formik}
            />
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4 w-full"
            >
                <h1 className="text-lg font-bold">Mill Dispatch</h1>
                <div className="grid grid-cols-2 gap-2">
                    <FormikAsyncSelect
                        name="vendor"
                        label="Vendor"
                        formik={formik}
                        getOptions={async (value) => {
                            const action = await dispatch(
                                fetchContacts({
                                    search: value,
                                    contact_type: 'vendor',
                                })
                            );
                            const serverResp = action.payload.contacts.map(
                                (ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_id}/${ele.contact_name}`,
                                })
                            );
                            return serverResp;
                        }}
                        onChange={(option) => {
                            const id = option.value.split('/')[0];
                            const vendor_name = option.value.split('/')[1];
                            formik.setFieldValue('vendorName', vendor_name);
                            formik.setFieldValue('vendor', id);
                        }}
                    />
                    <FormikSelectGroup
                        name={'location'}
                        label="Location"
                        options={generateOptions({
                            array: location?.docs,
                            labelField: 'name',
                            valueField: '_id',
                        })}
                        formik={formik}
                    />
                </div>
                <div className="flex flex-col  w-full gap-2  mb-1">
                    <label className="block text-sm font-medium" htmlFor={name}>
                        Batch No
                    </label>

                    <AsyncSelect
                        loadOptions={async (value) => {
                            const searchPayload = {
                                search: value,
                            };
                            const searchString =
                                QueryString.stringify(searchPayload);
                            const batchResp = await authAxiosInstance.get(
                                `/inventory/byBatchNo?${searchString}`
                            );
                            const batchDocs = batchResp.data?.data?.docs?.length
                                ? batchResp.data.data.docs
                                : [];
                            const batchOptions = batchDocs.map((ele) => ({
                                label: ele._id.batchNo,
                                value: ele._id.batchNo,
                            }));
                            return batchOptions;
                        }}
                        onChange={async (selectedOption) => {
                            const conditionsPayload = {
                                '_id.batchNo': selectedOption.value,
                            };
                            const conditionString =
                                QueryString.stringify(conditionsPayload);
                            const generalDataResp = await authAxiosInstance.get(
                                `/inventory/byBatchNo/byTakaNo?${conditionString}`
                            );
                            const generalData = generalDataResp?.data?.data
                                ?.docs?.length
                                ? generalDataResp.data.data.docs[0]
                                : null;
                            console.log('general data', generalData);
                            const takaDetails = generalData.takaDetails.map(
                                (ele) => ({
                                    takaId: ele._id.takaId,
                                    takaNo: ele.takaNo,
                                    stockQuantity: ele.balance,
                                    sendQuantity: ele.balance,
                                    remaining: 0,
                                    status: 'active',
                                })
                            );
                            const totalMeter = generalData.takaDetails.reduce(
                                (acc, ele) => {
                                    acc += ele.balance;
                                    return acc;
                                },
                                0
                            );
                            const totalTaka = generalData.takaDetails.length;
                            if (!generalData) {
                                alert('Batch Taka not in stock');
                                return;
                            }
                            console.log(generalData);
                            formik.setFieldValue('batches', [
                                ...formik.values.batches,
                                {
                                    batchNo: generalData._id.batchNo,
                                    takaDetails: takaDetails,
                                    greyPoRef: generalData.greyPoRef._id,
                                    quality:
                                        generalData.takaDetails[0].quality.name,
                                    qualityId:
                                        generalData.takaDetails[0].quality._id,
                                    billNo: generalData.takaDetails[0].billNo,
                                    totalMeter: totalMeter,
                                    totalTaka,
                                    master_grey_vendor_name:
                                        generalData.greyPoRef
                                            .master_grey_vendor_name,
                                },
                            ]);
                        }}
                    />
                </div>
                <div className="flex flex-col gap-4">
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="batches"
                            render={(arrayHelpers) => {
                                return (
                                    <>
                                        <table className="table-auto w-full">
                                            <TableHeader
                                                headers={[
                                                    {
                                                        name: 'Sr No.',
                                                        style: {
                                                            minWidth: '20px',
                                                        },
                                                    },
                                                    {
                                                        name: 'Batch no',
                                                        style: {
                                                            minWidth: '80px',
                                                        },
                                                    },
                                                    {
                                                        name: 'Master Vendor Name',
                                                        style: {
                                                            minWidth: '80px',
                                                        },
                                                    },
                                                    {
                                                        name: 'Quality',
                                                        style: {
                                                            minWidth: '80px',
                                                        },
                                                    },
                                                    {
                                                        name: 'Taka',
                                                        style: {
                                                            minWidth: '20px',
                                                        },
                                                    },
                                                    {
                                                        name: 'Meter',
                                                        style: {
                                                            minWidth: '80px',
                                                        },
                                                    },
                                                    {
                                                        name: 'Action',
                                                        style: {
                                                            minWidth: '80px',
                                                        },
                                                    },
                                                ]}
                                            />
                                            <tbody className="text-sm">
                                                {formik.values.batches.map(
                                                    (ele, i) => (
                                                        <tr>
                                                            <MillDispatchRow
                                                                formik={formik}
                                                                arrayHelpers={
                                                                    arrayHelpers
                                                                }
                                                                element={ele}
                                                                index={i}
                                                            />
                                                            <td>
                                                                <PrimaryButton
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setEditTakaDetailsModalOpen(
                                                                            true
                                                                        );
                                                                        setSelectedBatchForEdit(
                                                                            {
                                                                                takaDetails:
                                                                                    ele.takaDetails,
                                                                                index: i,
                                                                            }
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </PrimaryButton>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                );
                            }}
                        />
                    </FormikProvider>
                </div>
                <div>
                    {formik.isSubmitting || loading ? (
                        <ClipLoader />
                    ) : (
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    )}{' '}
                </div>
            </form>
        </div>
    );
};

export default MillDispatch;
